import React, { useEffect, useState } from 'react';
import DPNotificationsEvents from '../Context/DPNotifications';

import {Snackbar, Alert, Stack, Slide} from '@mui/material'


const SlideTransition = (props) => {
    return <Slide {...props} direction="down" />
}


const AppMessageQueue = (props) => {

    const {duration} = props;

    const [notificationQueue, setNotificationQueue] = useState([]);
    const [showNotification, setShowNotification] = useState(false);
    const [currentNotification, setCurrentNotification] = useState(undefined);
    

    useEffect(() => {

        const receivedNotification = (notification) => {
            if(!notificationQueue.includes(notification))    
            {
                
                setNotificationQueue((prev) => [...prev, {...notification}]);
            }
        }
    
    
        DPNotificationsEvents.on(receivedNotification);

        return DPNotificationsEvents.remove();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {       
        //console.log(notificationQueue);

        if (notificationQueue.length && !currentNotification) 
        {
            // Set a new notification when we don't have an active one
            setCurrentNotification({ ...notificationQueue[0] });
            setNotificationQueue((prev) => prev.slice(1));
            setShowNotification(true);
        }
        else if (notificationQueue.length && currentNotification && showNotification) 
        {
            // Close an active snack when a new one is added
            //setShowNotification(false);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationQueue, currentNotification, showNotification])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowNotification(false);
    };

    const handleExited = () => {
        setCurrentNotification(undefined);
    };
    


    return (
            <Stack
                direction="column"

            >
                <Snackbar 
                    key={currentNotification?currentNotification.id : undefined} 
                    open={showNotification} 
                    autoHideDuration={duration}
                    onClose={handleClose}
                    anchorOrigin={{ vertical:"top", horizontal:"center" }}
                    TransitionComponent={SlideTransition}
                    TransitionProps={{ onExited: handleExited }}
                >
                    <Alert severity={currentNotification?.type} sx={{ width: '100%' }}>
                        {currentNotification?.message}
                    </Alert>
                </Snackbar>

            </Stack>
            
    )
}




export default AppMessageQueue;