import React, { useMemo, useState, useEffect } from "react";
import { useDPApi } from "../Context/DPApi";
import {
	Backdrop,
	Modal,
	Fade,
	Typography,
	Box,
	Paper,
	Button,
	Grid,
	TextField,
	MenuItem,
	Select,
	InputLabel,
	Divider,
} from "@mui/material";
import {
	FormControl,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
} from "@mui/material";
import {
	Toolbar,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TablePagination,
	TableRow,
	TableCell,
	TableSortLabel,
} from "@mui/material";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";

import {
	Search,
	AddCircleOutline,
	Save,
	Edit,
	Close,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";

import BoxDetails from "../Comps/BoxDetails";

//remove this is TEMP
// import boxesJSON from "../Const/BoxesData.json";

const BoxesPage = (props) => {
	//State Management
	const CHARACTER_LIMIT = 8;
	const [rawBoxesData, setRawBoxesData] = useState([]);
	const [boxesData, setBoxesData] = useState([]);
	const [filteredBoxData, setFilteredBoxData] = useState([]);
	const [quickSearchValue, setQuickSearchValue] = useState("");
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const [poObject, setPOObject] = useState({});

	const [showSelectedDialog, setShowSelectedDialog] = useState(false);
	const [selectedTableRow, setSelectedTableRow] = useState({});

	//----------------  Data from DPAPI  -----------------------------------

	const { CompanyInfo } = useDPApi();
	const { BoxInfo } = useDPApi();
	const { LinesInfo } = useDPApi();

	//-----------------  Invoke Get all data  ------------------------------

	const { data: companiesData } = CompanyInfo.useGetCompanies();
	const { data: BoxInfoData } = BoxInfo.useGetBoxes();
	const { data: LinesInfoData } = LinesInfo.useGetLines();

	//-------------------  Pass data to states  ----------------------------

	const [Companies, setCompanies] = useState([]);
	const [getBoxes, setBoxes] = useState([]);
	const [getLines, setLines] = useState([]);

	//-------------------  sanitize data  ----------------------------

	const sanitizeCompaniesData = () => {
		let tempData = companiesData;
		setCompanies([...tempData]);
	};

	const sanitizeBoxesData = () => {
		let tempData = BoxInfoData;
		// console.log(BoxInfoData);
		setBoxes([...tempData]);
	};
	const sanitizeLinesData = () => {
		let tempData = LinesInfoData;
		//  console.log(LinesInfoData);
		setLines([...tempData]);
	};
	//-------------------  Invoke API data on render   ----------------------------

	useEffect(() => {
		if (companiesData) {
			sanitizeCompaniesData();
		}
	}, [companiesData]);

	useEffect(() => {
		if (BoxInfoData) {
			sanitizeBoxesData();
		}
	}, [BoxInfoData]);

	useEffect(() => {
		if (LinesInfoData) {
			sanitizeLinesData();
		}
	}, [LinesInfoData]);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------
	//-- Destructered constant data  --
	//------------------------------------------------------------------------------------------------

	const [currentBoxObject, setCurrentBoxObject] = useState({});
	useMemo(() => {
		let NewBoxObject = BoxInfo.BoxTemplate;

		NewBoxObject.BoxBoxNumber = "";
		NewBoxObject.BoxDescription = "";
		NewBoxObject.BoxDateBoxReceived = "";
		NewBoxObject.BoxLocalBoxRef = "";
		NewBoxObject.BoxOrderNumber = "";
		NewBoxObject.BoxCompanyId = "";
		setCurrentBoxObject({ ...NewBoxObject });
	}, []);

	const [currentLineObject, setCurrentLineObject] = useState({});
	useMemo(() => {
		let NewLineObject = LinesInfo.LinesTemplate;

		NewLineObject.LinesDescription = "";
		NewLineObject.LinesKeyword = "";
		NewLineObject.LinesDatefrom = "";
		NewLineObject.LinesDateto = "";
		NewLineObject.LinesLocalLinesReference = "";
		NewLineObject.LinesCreatedDate = "";
		setCurrentLineObject({ ...NewLineObject });

		console.log(NewLineObject);
	}, []);

	const headCells = [
		{
			id: "BoxBoxNumber",
			numeric: false,
			disablePadding: true,
			label: "Box Nr",
		},
		// {
		// 	id: "BoxDescription",
		// 	numeric: false,
		// 	disablePadding: true,
		// 	label: "Box Description",
		// },
		{
			id: "BoxDateBoxReceived",
			numeric: false,
			disablePadding: true,
			label: "Box Received Date",
		},
		{
			id: "BoxLocalBoxRef",
			numeric: false,
			disablePadding: true,
			label: "Local Box Ref",
		},
		{
			id: "BoxOrderNumber",
			numeric: false,
			disablePadding: true,
			label: "Order Nr",
		},
		{
			id: "BoxStatus",
			numeric: false,
			disablePadding: true,
			label: "Status",
		},
		{
			id: "BoxBranch",
			numeric: false,
			disablePadding: true,
			label: "Branch",
		},
	];

	//------------------------------------------------------------------------------------------------
	// Handle Box onText Change
	//------------------------------------------------------------------------------------------------

	const onTextChange = (e, key, type = "text") => {
		console.log(e, key);
		if (type === "text") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentBoxObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}
	};

	//-------------------------------------------------------------
	//        Handle Lines Box Data
	//---------------------------------------------------------------
	const OnTextChange = (e, key, type = "text") => {
		console.log(e, key);
		if (type === "text") {
			let tempObject = { ...currentLineObject };
			tempObject[key] = e.target.value;
			setCurrentLineObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentLineObject };
			tempObject[key] = e.target.value;
			setCurrentLineObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentLineObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentLineObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentLineObject };
			tempObject[key] = e.target.value;
			setCurrentLineObject({ ...tempObject });
		}
	};

	//-------------------------------------------------------------
	//        Handle Post Box Data
	//---------------------------------------------------------------
	const handlePostPost = (e) => {
		e.preventDefault();
		// console.log(currentBoxObject);
		let postingObject = { ...currentBoxObject };
		BoxInfo.AddBox(postingObject);
		setOpen(false);
	};
	//-------------------------------------------------------------
	//        Handle Post Box Line Data
	//---------------------------------------------------------------
	const handleLinePost = (e) => {
		e.preventDefault();
		// console.log(currentBoxObject);
		let postingObject = { ...currentLineObject };
		LinesInfo.AddLine(postingObject);
		setOpenLine(false);
	};
	// ------------------------------------------------------------------------------------------------
	//  Load and Sanitize Data
	// ------------------------------------------------------------------------------------------------
	const sanitizeBoxData = () => {
		let tempData = [];

		getBoxes.map((box) => {
			let tempBox = {};
			tempBox.BoxGuid = box.BoxGuid;

			headCells.map((header) => {
				tempBox[header.id] = box[header.id];
				return header;
			});

			//normalize dates
			if (tempBox.BoxDateBoxReceived) {
				tempBox.BoxDateBoxReceived = new Date(box.BoxDateBoxReceived)
					.toISOString()
					.split("T")[0];
			}
			tempBox.Lines = box.Lines;
			tempBox.FullLines = box.FullLines;

			tempData.push(tempBox);
			return box;
		});

		setFilteredBoxData([...tempData]);
		setBoxesData([...tempData]);
		setShowLoadingSpinner(false);
	};

	useMemo(() => {
		if (getBoxes) {
			sanitizeBoxData();
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getBoxes]);

	//temp remove
	useMemo(() => {
		//loadingDataBase data temps
		setShowLoadingSpinner(true);
		setRawBoxesData([...getBoxes]);
	}, []);

	// ------------------------------------------------------------------------------------------------
	//  Search and Filter
	// ------------------------------------------------------------------------------------------------
	const handleQuickSearchChange = (event) => {
		setQuickSearchValue(event.target.value);

		//filter values here
		let tempDataArray = boxesData.filter((box) => {
			let canReturn = false;
			Object.keys(box).map((key) => {
				if (
					key !== "BoxGuid" &&
					String(box[key])
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				) {
					canReturn = true;
				}
				return box;
			});
			return canReturn;
		});

		setFilteredBoxData([...tempDataArray]);
	};

	const HighlightText = (props) => {
		const { value } = props;

		if (quickSearchValue) {
			if (
				String(value).toLowerCase().includes(quickSearchValue.toLowerCase())
			) {
				//construct the array
				let myTextArray = String(value)
					.toLowerCase()
					.split(quickSearchValue.toLowerCase());

				return (
					<div>
						{String(value).slice(0, myTextArray[0].length)}
						<Box sx={{ background: "#f07d06", display: "inline-flex" }}>
							{String(value).slice(
								myTextArray[0].length,
								myTextArray[0].length + quickSearchValue.length
							)}
						</Box>
						{String(value).slice(
							myTextArray[0].length + quickSearchValue.length
						)}
					</div>
				);
			}
		}

		return value;
	};

	//------------------------------------------------------------------------------------------------
	// Handle new form popup
	//------------------------------------------------------------------------------------------------

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 800,
		bgcolor: "background.paper",
		borderRadius: "8px",
		boxShadow: 24,
		p: 4,
	};

	const [openLine, setOpenLine] = React.useState(false);
	const handleLineOpen = () => setOpenLine(true);
	const handleLineClose = () => setOpenLine(false);

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	//------------------------------------------------------------------------------------------------
	// Handle table selection
	//------------------------------------------------------------------------------------------------
	const handleRowClick = (item) => {
		let selectedBoxes = getBoxes.filter(
			(boxRow) => boxRow.BoxGuid === item.BoxGuid
		)[0];
		setSelectedTableRow(selectedBoxes);
		setShowSelectedDialog(true);
	};

	// ------------------------------------------------------------------------------------------------
	//  Pagination
	// ------------------------------------------------------------------------------------------------
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ------------------------------------------------------------------------------------------------
	//  Order and Sorting
	// ------------------------------------------------------------------------------------------------
	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - boxesData.length) : 0;

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}));

	//----------Prefilled From Edit----------------------------------
	const [editingBoxInDialog, setEditingBoxInDialog] = useState(false);

	// const [editingDialog, setEditingDialog] = useState(false);

	const changeDialogType = () => {
		setEditingBoxInDialog(!editingBoxInDialog);
		// setEditingDialog(showEditingDialog);
	};

	const updateCurrentSelectedRowInDialog = (newBoxObject) => {
		setSelectedTableRow({ ...newBoxObject });
	};

	return (
		<Box>
			{/* form modal data start here */}

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						{/* --------------Form Comes Here-------------------- */}

						<Divider>Box Detail Form</Divider>

						<Box>
							{/* sx={style} */}
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										label="Box Number"
										variant="outlined"
										inputProps={{
											maxlength: CHARACTER_LIMIT,
										}}
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxBoxNumber}
										onChange={(e) => onTextChange(e, "BoxBoxNumber")}
									/>

									<TextField
										label="Order Number"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxOrderNumber}
										onChange={(e) => onTextChange(e, "BoxOrderNumber")}
									/>
								</Grid>
								{/**--------Grid 2------------- */}

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										id="filled-number"
										label="Box Received Date"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxDateBoxReceived}
										onChange={(e) => onTextChange(e, "BoxDateBoxReceived")}
									/>

									<TextField
										label="Local Box Ref"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxLocalBoxRef}
										onChange={(e) => onTextChange(e, "BoxLocalBoxRef")}
									/>
								</Grid>

								{/* -------------Grid 3------------- */}
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<Select
										defaultValue={0}
										label="Select Customer"
										variant="outlined"
										size="small"
										sx={{ width: "48%" }}
										value={poObject.BoxCompanyId}
										onChange={(e) => onTextChange(e, "BoxCompanyId")}
									>
										<MenuItem size="small" value={0}>
											<InputLabel>Customer</InputLabel>
										</MenuItem>

										{Companies.map((companyItem) => {
											return (
												<MenuItem
													size="small"
													key={companyItem.Guid}
													value={companyItem.Guid}
												>
													{companyItem.CompanyName} ({companyItem.AccCode})
												</MenuItem>
											);
										})}
									</Select>
								</Grid>

								{/**----------------------- */}
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										sx={{
											// ml: 42.5,
											// mt: 3,
											color: "white",
										}}
										variant="contained"
										size="small"
										// color="success"
										startIcon={<Save />}
										onClick={handlePostPost}
									>
										Save
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>
			{/* --------------Box Form Comes Here-------------------- */}

			{/* form modal data start here */}

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={openLine}
				onClose={handleLineClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openLine}>
					<Box sx={style}>
						{/* --------------Line Box Form Comes Here-------------------- */}

						<Divider>Lines Detail Form</Divider>

						<Box>
							{/* sx={style} */}
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										label="Keyword"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.LinesKeyword}
										onChange={(e) => OnTextChange(e, "LinesKeyword")}
									/>
									<TextField
										label="Local Ref"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.LinesLocalLinesReference}
										onChange={(e) =>
											OnTextChange(e, "LinesLocalLinesReference")
										}
									/>
								</Grid>
								{/**--------Grid 2------------- */}

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										id="filled-number"
										label="Date From"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										value={currentBoxObject.LinesDatefrom}
										onChange={(e) => OnTextChange(e, "LinesDatefrom")}
									/>

									<TextField
										id="filled-number"
										label="Date To"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										value={currentBoxObject.LinesDateto}
										onChange={(e) => OnTextChange(e, "LinesDateto")}
									/>
								</Grid>

								{/* -------------Grid 3------------- */}
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<FormControl sx={{ m: 1, minWidth: "48%" }}>
										<InputLabel id="BoxLinked_SelectLabel">Link Box</InputLabel>
										<Select
											defaultValue={0}
											label="Link Box"
											variant="outlined"
											size="small"
											// sx={{ width: "50%" }}
											value={currentBoxObject.BoxName}
											onChange={(e) => OnTextChange(e, "BoxName")}
										>
											{/* <MenuItem size="small" value="">
											Link Box
										</MenuItem> */}
											<MenuItem value={10}>Box One</MenuItem>
											<MenuItem value={20}>Box Two</MenuItem>
											<MenuItem value={30}>Box Three</MenuItem>
											<MenuItem value={40}>Box Four</MenuItem>
										</Select>
									</FormControl>

									<TextField
										id="filled-number"
										label="Created Date"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										value={currentBoxObject.LinesCreatedDate}
										onChange={(e) => OnTextChange(e, "LinesCreatedDate")}
									/>
								</Grid>

								{/**----------------------- */}
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										sx={{
											// ml: 42.5,
											// mt: 3,
											color: "white",
										}}
										variant="contained"
										size="small"
										startIcon={<Save />}
										onClick={handleLinePost}
									>
										Save
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>
			{/* --------------Line Box Form Close Here-------------------- */}

			{/* form modal data end here */}
			<Dialog
				open={showSelectedDialog}
				onClose={(e) => setShowSelectedDialog(false)}
				maxWidth={"lg"}
			>
				<DialogTitle>{selectedTableRow.BoxBoxNumber} Details</DialogTitle>
				<DialogContent>
					<BoxDetails
						// editing={editingDialog}
						boxObject={selectedTableRow}
						IsEditing={editingBoxInDialog}
						updateCurrentBoxObject={updateCurrentSelectedRowInDialog}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						size="small"
						startIcon={<Edit />}
						// onClick={(e) => setEditingBoxInDialog(!editingBoxInDialog)}
						onClick={(e) => changeDialogType(true)}
					>
						Edit
					</Button>

					<Button
						variant="contained"
						size="small"
						startIcon={<Close />}
						onClick={(e) => setShowSelectedDialog()}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Paper>
				<Box>
					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
						}}
					>
						<Typography
							sx={{ flex: "1 1" }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							Boxes
						</Typography>
						<FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
							{/* new Add Box button start */}
							<Grid
								container
								spacing={2}
								mb={2}
								display={"flex"}
								justifyContent={"flex-end"}
							>
								<Grid item xs={10}>
									<Button
										sx={{ m: 2 }}
										size="small"
										variant="contained"
										startIcon={<AddCircleOutline />}
										onClick={handleLineOpen}
									>
										New Line
									</Button>

									<Button
										sx={{ m: 2 }}
										size="small"
										variant="contained"
										startIcon={<AddCircleOutline />}
										onClick={handleOpen}
									>
										{/* <Link className="clink" to="/NewOrder">
                    </Link> */}
										New Box
									</Button>
								</Grid>
							</Grid>
							{/* new Add Box button end */}

							<OutlinedInput
								id="quickSearch_txt"
								variant="outlined"
								value={quickSearchValue}
								size="small"
								onChange={handleQuickSearchChange}
								endAdornment={
									<InputAdornment position="end">
										<Search />
									</InputAdornment>
								}
							/>
						</FormControl>
					</Toolbar>

					<TableContainer sx={{ padding: 2 }}>
						<Table size="small">
							{/* -----------------------Table Header--------------------- */}
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id}
											align={headCell.numeric ? "right" : "left"}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : "asc"}
												onClick={createSortHandler(headCell.id)}
											>
												{headCell.label}

												{orderBy === headCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{showLoadingSpinner && (
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ padding: 3 }}>
											<CircularProgress color="secondary" />
										</TableCell>
									</TableRow>
								)}

								{!showLoadingSpinner &&
									filteredBoxData
										.slice()
										.sort(getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<StyledTableRow
													hover
													tabIndex={-1}
													key={row.BoxGuid}
													onClick={(e) => handleRowClick(row)}
													sx={{ cursor: "pointer" }}
												>
													{headCells.map((headCell) => {
														return (
															<TableCell key={row.BoxGuid + headCell.id}>
																<HighlightText value={row[headCell.id] || ""} />
															</TableCell>
														);
													})}
												</StyledTableRow>
											);
										})}
								{!showLoadingSpinner && emptyRows > 0 && (
									<StyledTableRow
										style={{
											height: 33 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component="div"
						count={boxesData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Box>
			</Paper>
		</Box>
	);
};
export default BoxesPage;
