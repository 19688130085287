import React, { useState } from "react";
import "./App.css";

//Components
import { AppNavigation } from "./Comps/AppNavigator";
import { AppHeader } from "./Comps/AppHeader";
import AppMessageQueue from "./Comps/AppMessageQueue";

//Navigation and Routes Declarations
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as ROUTES from "./Const/routes";

import Login from "./Pages/Login";

//Import Pages
// import Dashboard from "./Pages/Dashboard";
// import NotificationsPage from "./Pages/Notifications";
import BoxesPage from "./Pages/Boxes";

import CasesPage from "./Pages/Cases";
import CompanyPages from "./Pages/Company";
import UsersPages from "./Pages/Users";

import NewOrder from "./Pages/NewOrder";
// import DeRegistration from "./Pages/DeRegistration";
// import HelpPage from "./Pages/Help";
// import SettingsPage from "./Pages/Settings";

import { useDPAuth } from "./Context/DPApi";
import { useAppSettings } from "./Context/AppSettings";

import { Box, CssBaseline, Hidden } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const DrawerWidth = 240;

const App = () => {
	const [mobileOpen, setMobileOpen] = useState(false);
	const { isAuth } = useDPAuth();
	const { currentTheme } = useAppSettings();

	const theme = createTheme(currentTheme);

	//helper functions
	const toggleMobileMenu = (newValue = !mobileOpen) => {
		setMobileOpen(newValue);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: "flex", flex: 1 }}>
				<CssBaseline />

				{/* CHANGE THIS FLAG TO IMPLEMENT AUTH ON ROUTES */}
				{/* {isAuth? :: uses auth on routes */}
				{/* {!isAuth? :: no auth on routes */}
				{isAuth ? (
					<Router>
						<Box
							component="nav"
							sx={{
								width: { sm: DrawerWidth },
								display: `flex`,
								flexShrink: { sm: 0 },
							}}
						>
							<Hidden smUp>
								<AppNavigation
									drawerWidth={DrawerWidth}
									variant="temporary"
									toggleMobileOpen={toggleMobileMenu}
									mobileMenuOpen={mobileOpen}
								/>
							</Hidden>
							<Hidden smDown>
								<AppNavigation
									drawerWidth={DrawerWidth}
									variant="permanent"
									toggleMobileOpen={toggleMobileMenu}
									mobileMenuOpen={mobileOpen}
								/>
							</Hidden>
						</Box>

						<Box
							component="main"
							sx={{
								display: "flex",
								width: `100%`,
								flexDirection: `column`,
								flex: 1,
							}}
						>
							<Box>
								<AppHeader
									toggleMobileOpen={toggleMobileMenu}
									mobileMenuOpen={mobileOpen}
								/>
							</Box>

							<Box sx={{ padding: "10px", paddingBottom: 0 }}>
								<Routes>
									<Route path={ROUTES.DASHBOARD} element={<CasesPage />} />

									<Route path={ROUTES.BOXES} element={<BoxesPage />} />
									<Route path={ROUTES.CASES} element={<CasesPage />} />
									{/* <Route path={ROUTES.DEREGISTRATION} element={<DeRegistration />} /> */}

									<Route path={ROUTES.COMPANIES} element={<CompanyPages />} />
									<Route path={ROUTES.USERS} element={<UsersPages />} />

									<Route path={ROUTES.NEWORDER} element={<NewOrder />} />
									{/* <Route path={ROUTES.HELP} element={<HelpPage />} /> */}
									{/* <Route path={ROUTES.SETTINGS} element={<SettingsPage />} /> */}
								</Routes>
							</Box>
						</Box>
					</Router>
				) : (
					<Login />
				)}
			</Box>

			<AppMessageQueue duration={3000} />
		</ThemeProvider>
	);
};

export default App;
