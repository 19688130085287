export const DASHBOARD = "/";

// Global Users Menus
export const NOTIFICATIONS = "/Notifications";
export const BOXES = "/Boxes";
export const CASES = "/Cases";
export const COMPANIES = "/Company";
export const USERS = "/Users";
// export const DEREGISTRATION = "/DeRegistration"

export const NEWORDER = "/NewOrder";
export const HELP = "/Help";
export const SETTINGS = "/Settings";

// Auth Routes
export const REGISTER = "/Register";
export const FORGOTPASSWORD = "/ForgotPassword";
export const SIGN_IN = "/Sign In";
