import React, {useState, useEffect, useMemo} from 'react';

import {useDPAuth} from '../Context/DPApi'

import {AppLogo} from '../Comps/AppExtras';


import {Stack, Paper, Container, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircle from '@mui/icons-material/AccountCircle';








const Login = () => {

    const {Auth} = useDPAuth();
    useEffect(() => {Auth.checkAuth();},[Auth]);

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState('');
    const [forceResetPassword, setForceResetPassword] = useState(false);

    useEffect(()=> {
        if(Auth.resettingPassword)
        {
            setForceResetPassword(Auth.resettingPassword);
        }
    }, [Auth])

    const handleUserNameChange = (e) => {
        setUserName(e.target.value);
    }

    //Password functions
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    //////////////////////////////////////////////////////

    // Confirm password for reset functions
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    /////////////////////////////////////////////////////////

    const handleResetPassword = () => {
        setUserName("");
        setPassword("");
        Auth.forceResetPassword();
    }

    const handleDoneResetPassword = () => {
        setForceResetPassword(false);
        Auth.doneResetPassword();
    }

    return(
        <Container maxWidth="sm" >
            <Paper>
                    {
                        !forceResetPassword && 
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={2}
                                mt="100px"
                                p="10px"
                            >

                                    <AppLogo />
                                    
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="usernameInput">Username</InputLabel>
                                        <OutlinedInput
                                            id="usernameInput"
                                            type="text"
                                            value={username}
                                            onChange={ handleUserNameChange }  

                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <AccountCircle />
                                                            </InputAdornment>
                                                        }
                                            label="Username"
                                        />
                                    </FormControl>
                                    

                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="passwordInput">Password</InputLabel>
                                        <OutlinedInput
                                            id="passwordInput"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={ handlePasswordChange }  

                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                            label="Password"
                                        />
                                    </FormControl>

                                    <Button 
                                        variant="contained"
                                        onClick={() => {Auth.loginWithUsernamePassword(username, password)}}
                                    > 
                                        Login 
                                    </Button>


                                    <Typography variant="body2" align="center">OR</Typography>

                                    <Button
                                        variant="contained"
                                        onClick={(e) => handleResetPassword(e)}
                                    > 
                                        Reset Password
                                    </Button>
                            
                            </Stack>
                    }

                    {
                        forceResetPassword && 
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={2}
                                mt="100px"
                                p="10px"
                            >

                                    <AppLogo />                                   

                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="passwordInput">Password</InputLabel>
                                        <OutlinedInput
                                            id="passwordInput"
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={ handlePasswordChange }  

                                            endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                            label="Password"
                                        />
                                    </FormControl>


                                        <FormControl variant="outlined">
                                            <InputLabel htmlFor="passwordConfirmInput">Confirm Password</InputLabel>
                                            <OutlinedInput
                                                id="passwordConfirmInput"
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                value={confirmPassword}
                                                onChange={ handleConfirmPasswordChange }  

                                                endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowConfirmPassword}
                                                                        onMouseDown={handleMouseDownConfirmPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                label="Confirm Password"
                                            />
                                        </FormControl>

                                        <Button 
                                            variant="contained"
                                            onClick={() => {Auth.loginWithUsernamePassword(username, password)}}
                                        > 
                                            Save new Password
                                        </Button>

                                        <Typography variant="body2" align="center">OR</Typography>

                                        <Button
                                            variant="contained"
                                            onClick={() => handleDoneResetPassword()}
                                        > 
                                            Login
                                        </Button>
                            
                            </Stack>

                    }
                    
            </Paper>

        </Container>
    )
}


export default Login;