import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDPAuth } from "../Context/DPApi";

import { useNavigate } from "react-router-dom";
import * as ROUTES from "../Const/routes";

import { useAppSettings } from "../Context/AppSettings";
import { AppLogo } from "./AppExtras";

import {
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
	ListItemIcon,
	Box,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";

import NotificationsIcon from "@mui/icons-material/Notifications";

// import SettingsTwoTone from "@mui/icons-material/SettingsTwoTone";
import HelpOutline from "@mui/icons-material/HelpOutline";
import InventoryIcon from "@mui/icons-material/Inventory";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Group, LocationCity } from "@mui/icons-material";

function AppNavigation(props) {
	const { Auth } = useDPAuth();
	const [authLevel, setAuthLevel] = React.useState(3); // For Conditional Drawer Option Rendering

	const { window } = props;
	const container =
		window !== undefined ? () => window().document.body : undefined;
	const navigate = useNavigate();

	//prop drilled values
	const { mobileMenuOpen, toggleMobileOpen } = { ...props };

	//State management
	let currentRoute = useLocation().pathname;
	if (currentRoute === "/") {
		currentRoute = "Dashboard";
	}
	currentRoute = currentRoute.replace(/\//g, "");

	//helper functions
	const { darkTheme } = useAppSettings();
	const drawerTheme = createTheme(darkTheme);

	useEffect(() => {
		if (Auth.authUser) {
			if (!isNaN(parseInt(Auth.authUser.User.Level))) {
				setAuthLevel(parseInt(Auth.authUser.User.Level));
			} else {
				setAuthLevel(5);
			}
		}
	}, [Auth]);

	const navigateTo = (route, title, data = {}) => {
		let routeState = { routeTitle: title, routeData: data };

		navigate(route, { state: routeState });
		toggleMobileOpen(false);
	};

	return (
		<ThemeProvider theme={drawerTheme}>
			<Drawer
				variant={props.variant}
				container={container}
				open={mobileMenuOpen}
				onClose={() => {
					toggleMobileOpen(false);
				}}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				<Box
					sx={{
						width: props.drawerWidth,
						display: `flex`,
						flexShrink: { sm: 0 },
					}}
				>
					<List disablePadding sx={{ width: `100%` }}>
						<AppLogo />

						{/* 
                    ------------------------------------------------------------------------------------------------------
                            User Menus
                    ------------------------------------------------------------------------------------------------------
                */}

						<Divider />

						<ListItem
							button
							onClick={() => navigateTo(ROUTES.DASHBOARD, "Dashboard")}
							selected={"Dashboard" === currentRoute}
						>
							<ListItemIcon>
								<DashboardIcon />
							</ListItemIcon>
							<ListItemText> Dashboard </ListItemText>
						</ListItem>

						<div>
							<ListItem
								button
								onClick={() => navigateTo(ROUTES.BOXES, "Boxes")}
								selected={"Boxes" === currentRoute}
							>
								<ListItemIcon>
									<InventoryIcon />
								</ListItemIcon>
								<ListItemText> Boxes </ListItemText>
							</ListItem>

							<ListItem
								button
								onClick={() => navigateTo(ROUTES.CASES, "Cases")}
								selected={"Cases" === currentRoute}
							>
								<ListItemIcon>
									<ConfirmationNumberIcon />
								</ListItemIcon>
								<ListItemText> Cases </ListItemText>
							</ListItem>

							{/* <ListItem
                  button
                  onClick={() => navigateTo(ROUTES.DEREGISTRATION, "DeRegistration")}
                  selected={"DeRegistration" === currentRoute}
                >
                  <ListItemIcon>
                    <ConfirmationNumberIcon />
                  </ListItemIcon>
                  <ListItemText> DEREGISTER </ListItemText>
                </ListItem> */}

							<ListItem
								button
								onClick={() => navigateTo(ROUTES.COMPANIES, "Company")}
								selected={"Company" === currentRoute}
							>
								<ListItemIcon>
									<LocationCity />
								</ListItemIcon>
								<ListItemText> Companies </ListItemText>
							</ListItem>

							<ListItem
								button
								onClick={() => navigateTo(ROUTES.USERS, "Users")}
								selected={"Users" === currentRoute}
							>
								<ListItemIcon>
									<Group />
								</ListItemIcon>
								<ListItemText> Users </ListItemText>
							</ListItem>
						</div>
						{/* <ListItem
              button
              onClick={() => navigateTo(ROUTES.SETTINGS, "Settings")}
              selected={"Settings" === currentRoute}
            >
              <ListItemIcon>
                <SettingsTwoTone />
              </ListItemIcon>
              <ListItemText> Settings </ListItemText>
            </ListItem> */}

						<ListItem
							button
							onClick={() => navigateTo(ROUTES.HELP, "Help")}
							selected={"Help" === currentRoute}
						>
							<ListItemIcon>
								<HelpOutline />
							</ListItemIcon>
							<ListItemText> Help </ListItemText>
						</ListItem>

						<Divider />

						{/* 
                    ------------------------------------------------------------------------------------------------------
                            Platform / Global Menus
                    ------------------------------------------------------------------------------------------------------
                */}

						{authLevel === 1 ? (
							<div>
								<ListItem>
									<ListItemText> </ListItemText>
								</ListItem>

								<Typography variant="p">Platform Tools</Typography>

								<ListItem
									button
									onClick={() =>
										navigateTo(ROUTES.NOTIFICATIONS, "Notifications")
									}
									selected={"Notifications" === currentRoute}
								>
									<ListItemIcon>
										<NotificationsIcon />
									</ListItemIcon>
									<ListItemText> Notifications </ListItemText>
								</ListItem>
							</div>
						) : (
							""
						)}
					</List>
				</Box>
			</Drawer>
		</ThemeProvider>
	);
}

export { AppNavigation };
