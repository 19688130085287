import React, { useMemo, useState, useRef, useEffect } from "react";
// import { jsPDF } from "jspdf";
import { useDPApi } from "../Context/DPApi";
import SelectCompanies from "../Comps/SelectCompaniesComponent";
import logo from "../Logo.jpg";
import { Typography, Box, Stack, Paper, Button, Grid } from "@mui/material";
import { FormControl, OutlinedInput, InputLabel } from "@mui/material";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import { TextField, Select, MenuItem } from "@mui/material";
import {
	CancelOutlined,
	ArrowForwardIosOutlined,
	ArrowBackIosNewOutlined,
	SaveAltOutlined,
	SaveTwoTone,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

//remove this is TEMP
import { useReactToPrint } from "react-to-print";
import { typeOf } from "react-is";
//===============================================================
const NewOrder = (props) => {
	const navigate = useNavigate();
	const {
		editing,
		editingObject,
		viewingDetails,
		viewDeliveryNote,
		dialogInner,
		updateCurrentObject,
	} = props;

	//----------------  Data from DPAPI  -----------------------------------
	const { ServiceCaseType } = useDPApi();
	const { ServiceType } = useDPApi();
	const { ServiceCaseDescriptionType } = useDPApi();
	const { CompanyInfo } = useDPApi();
	const { CaseDetails } = useDPApi();
	const { SourcesInfo } = useDPApi();
	const { StatusInfo } = useDPApi();

	//-----------------  Invoke Get all data  ------------------------------
	const { data: caseTypesData } = ServiceCaseType.useGetCaseType();
	const { data: typesData } = ServiceType.useGetType();
	const { data: caseDescriptionTypesData } =
		ServiceCaseDescriptionType.useGetCaseDescriptionType();
	const { data: companiesData } = CompanyInfo.useGetCompanies();
	const { data: CasesData } = CaseDetails.useGetCases();
	const { data: SourceData } = SourcesInfo.useGetSource();
	const { data: StatusData } = StatusInfo.useGetStatus();

	//-------------------  Pass data to states  ----------------------------
	const [getCaseTypes, setCaseTypes] = useState([]);
	const [getTypes, setTypes] = useState([]);
	const [getCaseDescriptionTypes, setCaseDescriptionTypes] = useState([]);
	const [Companies, setCompanies] = useState([]);
	const [Cases, setCases] = useState([]);
	const [getSource, SetSource] = useState([]);
	const [getStatus, SetStatus] = useState([]);

	//-------------------  sanitize data  ----------------------------
	const sanitizeCaseTypesData = () => {
		let tempData = caseTypesData;
		setCaseTypes([...tempData]);
	};
	const sanitizeTypesData = () => {
		let tempData = typesData;
		setTypes([...tempData]);
	};
	const sanitizeCaseDescriptionTypesData = () => {
		let tempData = caseDescriptionTypesData;
		setCaseDescriptionTypes([...tempData]);
	};

	const sanitizeCompaniesData = () => {
		let tempData = companiesData;
		setCompanies([...tempData]);
	};

	const sanitizeCasesData = () => {
		let tempData = CasesData;
		setCases([...tempData]);
		//dummy usage of state for warning
		if (Cases) {
		}
	};
	const sanitizeSourceData = () => {
		let tempData = SourceData;
		SetSource([...tempData]);
		//dummy usage of state for warning
		if (getSource) {
		}
	};
	const sanitizeStatusData = () => {
		let tempData = StatusData;
		SetStatus([...tempData]);
		//dummy usage of state for warning
		if (getStatus) {
		}
	};

	//-------------------  Invoke API data on render   ----------------------------
	useEffect(() => {
		if (caseTypesData) {
			sanitizeCaseTypesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caseTypesData]);

	useEffect(() => {
		if (typesData) {
			sanitizeTypesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typesData]);

	useEffect(() => {
		if (caseDescriptionTypesData) {
			sanitizeCaseDescriptionTypesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [caseDescriptionTypesData]);

	useEffect(() => {
		if (companiesData) {
			sanitizeCompaniesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companiesData]);

	useEffect(() => {
		if (CasesData) {
			sanitizeCasesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [CasesData]);

	useEffect(() => {
		if (SourceData) {
			sanitizeSourceData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SourceData]);

	useEffect(() => {
		if (StatusData) {
			sanitizeStatusData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [StatusData]);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------
	//-- Destructered constant data  --
	//------------------------------------------------------------------------------------------------
	const Boxes = [
		{ id: 1, Guid: 1, label: "90000901" },
		{ id: 2, Guid: 2, label: "90000902" },
		{ id: 3, Guid: 3, label: "90000905" },
		{ id: 4, Guid: 4, label: "90000908" },
		{ id: 5, Guid: 5, label: "90000910" },
		{ id: 6, Guid: 6, label: "90000903" },
		{ id: 7, Guid: 7, label: "900009011" },
		{ id: 8, Guid: 8, label: "900009021" },
		{ id: 9, Guid: 9, label: "900009051" },
		{ id: 10, Guid: 10, label: "900009081" },
		{ id: 11, Guid: 11, label: "900009101" },
		{ id: 12, Guid: 12, label: "900009031" },
		{ id: 13, Guid: 13, label: "900009012" },
		{ id: 14, Guid: 14, label: "900009022" },
		{ id: 15, Guid: 15, label: "900009052" },
		{ id: 16, Guid: 16, label: "900009082" },
		{ id: 17, Guid: 17, label: "900009102" },
		{ id: 18, Guid: 18, label: "900009032" },
	];

	const [currentCaseObject, setCurrentCaseObject] = useState({});
	useMemo(() => {
		let newCaseObject = CaseDetails.CasesTemplate;

		newCaseObject.BoxLinked = [];
		newCaseObject.CaseAssignedUserId = "";
		newCaseObject.CaseDescriptionType = "";
		newCaseObject.CaseName = "";
		newCaseObject.CaseType = "";
		newCaseObject.ClosedBy = "";
		newCaseObject.ClosedDate = "";
		newCaseObject.Company = "";
		newCaseObject.CreatedBy = "";
		newCaseObject.CreatedDate = "";
		newCaseObject.Guid = "";
		newCaseObject.OrderDate = "";
		newCaseObject.PurchaseOrderNo = "";
		newCaseObject.Qty = 0;
		newCaseObject.ReferenceNo = "";
		newCaseObject.RequestDetail = "";
		newCaseObject.Source = "";
		newCaseObject.Status = "";
		newCaseObject.Type = "";
		newCaseObject.Data = {};

		setCurrentCaseObject({ ...newCaseObject });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [dialogCurrentStep, setDialogCurrentStep] = useState("new"); // new || summary || delivery_note || edit
	const onTextChange = (e, key, type = "text") => {
		if (type === "text") {
			let tempObject = { ...currentCaseObject };
			tempObject[key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentCaseObject };
			tempObject[key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentCaseObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentCaseObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentCaseObject };
			tempObject[key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}
	};

	const onTextDataChange = (e, key, cdGuid, type = "text") => {
		if (type === "text") {
			let tempObject = { ...currentCaseObject };
			tempObject.Data[cdGuid][key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentCaseObject };
			tempObject.Data[cdGuid][key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentCaseObject };
			tempObject.Data[cdGuid][key] = e.target.isChecked ? 1 : 0;
			setCurrentCaseObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentCaseObject };
			tempObject.Data[cdGuid][key] = e.target.value;
			setCurrentCaseObject({ ...tempObject });
		}
	};

	const getLinkedBoxesText = (cdGuid) => {
		if (currentCaseObject.Data[cdGuid]) {
			return currentCaseObject.Data[cdGuid].LinkedBoxes;
		} else {
			return null;
		}
	};
	const handleLinkBoxes = (event, cdGuid) => {
		let tempObject = { ...currentCaseObject };
		if (tempObject.Data[cdGuid]) {
			tempObject.Data[cdGuid].LinkedBoxes = event.target.value;
			setCurrentCaseObject({ ...tempObject });
		}
	};

	useEffect(() => {
		if (dialogInner) {
			try {
				if (typeof updateCurrentObject == "function") {
					updateCurrentObject(currentCaseObject);
				}
			} catch (e) {
				console.log("EXCEPTION :: => ", e);
			}
		}
	}, [currentCaseObject, dialogInner]);

	const handlePostPo = (e, back = false) => {
		console.log(currentCaseObject);
		if (!back) {
			if (dialogCurrentStep === "new") {
				loadSummary();
			}

			if (dialogCurrentStep === "summary") {
				loadDeliveryNote();
				let postingObject = { ...currentCaseObject };
				if (postingObject) {
					CaseDetails.AddCase(postingObject);
				}
			}

			if (dialogCurrentStep === "delivery_note") {
				navigate("/Cases");
			}
		} else {
			if (dialogCurrentStep === "summary") {
				loadNewOrEdit();
			}

			if (dialogCurrentStep === "delivery_note") {
				loadSummary();
			}
		}
	};

	const loadNewOrEdit = () => {
		setDialogCurrentStep("new");
	};
	const loadSummary = () => {
		setDialogCurrentStep("summary");
	};
	const loadDeliveryNote = () => {
		setDialogCurrentStep("delivery_note");
	};

	const [serviceType, setServiceType] = React.useState([]);
	const handleServiceType = (event) => {
		let tempObject = { ...currentCaseObject };
		tempObject.CaseDescriptionType = event.target.value;

		//check for data objects
		let tempData = { ...tempObject.Data };
		tempObject.CaseDescriptionType.map((cdType) => {
			if (!tempData[cdType]) {
				//does not contain the current type object in data
				tempData[cdType] = {
					Details: "",
					Qty: 0,
					LinkedBoxes: [],
				};
			}
		});

		//remove any keys not set in data yet
		Object.keys(tempObject.Data).map((cdGuid) => {
			if (!tempObject.CaseDescriptionType.includes(cdGuid)) {
				delete tempData[cdGuid];
			}
		});

		tempObject.Data = { ...tempData };
		console.log(tempObject);
		setCurrentCaseObject({ ...tempObject });

		const {
			target: { value },
		} = event;
		setServiceType(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	//---------------PDF download functions
	// --------------Create styles

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		documentTitle: "Report-data",
		// onAfterPrint: () => ("print success"),
	});
	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

	const cancelNewCase = (e) => {
		setDialogCurrentStep("new");
		setCurrentCaseObject({});
		navigate("/Cases");
	};

	useEffect(() => {
		if (editing) {
			setCurrentCaseObject({ ...editingObject });
			loadNewOrEdit();
		}

		if (viewingDetails) {
			setCurrentCaseObject({ ...editingObject });
			loadSummary();
		}

		if (viewDeliveryNote) {
			setCurrentCaseObject({ ...editingObject });
			loadDeliveryNote();
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editing, viewingDetails, viewDeliveryNote, dialogInner]);

	return (
		<>
			{/* <Dialog
        open={showAddNewDialog}
        onClose={(e) => setShowAddNewDialog(false)}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle>
          <Paper variant="outlined" sx={{ margin: 1, padding: 4 }}>
            {dialogTitleText}
          </Paper>
        </DialogTitle> */}
			{/* <DialogContent> */}
			{dialogCurrentStep === "summary" && (
				<Grid container mt={0} spacing={2} columns={{ xs: 1, sm: 2, md: 4 }}>
					{/* ----------------------------- Table Summary info here ------------------------------------*/}
					<TableContainer sx={{ padding: 0 }}>
						<Table size="small"></Table>
					</TableContainer>
					<Grid item xs={12}>
						{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
						{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
						{/* Summary info here ------------------------------------------------------------------------------------------------------------------- */}
						{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
						{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
						<Box padding={2}>
							<Stack>
								<Paper
									variant="outlined"
									sx={{ margin: 1, padding: 4, justifyContent: "center" }}
								>
									<Stack>
										<Typography component="h1" variant="h5" paddingY={1}>
											Summary
										</Typography>

										<Grid container mb={2} mt={5}>
											<Grid item sx={{ width: "50%" }}>
												<Grid container direction={"column"}>
													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Customer Name: </strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ mx: 1.5 }}>
																	{Companies.filter(
																		(comp) =>
																			comp.Guid === currentCaseObject.Company
																	)[0]?.CompanyName || "N/A"}
																</Typography>
															</Grid>
														</Grid>
													</Grid>

													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Created By:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ mx: 6 }}>
																	{currentCaseObject.createdBy || "N/A"}
																</Typography>
															</Grid>
														</Grid>
													</Grid>

													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Order Date:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ marginX: 6 }}>
																	{new Date().toISOString().split("T")[0]}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Created Date:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ marginX: 4 }}>
																	{new Date().toISOString().split("T")[0]}
																</Typography>
															</Grid>
														</Grid>
													</Grid>

													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Closed By:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ mx: 6.5 }}>
																	{currentCaseObject.createdBy || "N/A"}
																</Typography>
															</Grid>
														</Grid>
													</Grid>

													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Closed Date:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ marginX: 5 }}>
																	{new Date().toISOString().split("T")[0]}
																</Typography>
															</Grid>
														</Grid>
													</Grid>

													<Grid item>
														<Grid container>
															<Grid item>
																<Typography>
																	<strong>Request Details:</strong>
																</Typography>
															</Grid>
															<Grid item>
																<Typography sx={{ marginX: 5 }}>
																	{currentCaseObject.RequestDetail || "N/A"}
																</Typography>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>

										{/* Case Type Description : */}
										{currentCaseObject.CaseDescriptionType &&
											currentCaseObject.CaseDescriptionType.map((cdtItem) => {
												let currentDescriptionType =
													getCaseDescriptionTypes.filter(
														(currItem) => currItem.Guid === cdtItem
													)[0];

												let allowLinkBox = 0;
												let allowLinkQty = 0;
												if (currentDescriptionType?.Data) {
													allowLinkBox =
														currentDescriptionType.Data?.AllowLinkBox || 0;
													allowLinkQty =
														currentDescriptionType.Data?.AllowQty || 0;
												}
												return (
													<Box
														marginY={2}
														key={cdtItem + "_CaseDescriptionType"}
													>
														<Typography variant="h6">
															{currentDescriptionType?.Name || "N/A"}
														</Typography>
														<TableContainer component={Paper}>
															<Table
																sx={{ minWidth: 650 }}
																size="small"
																aria-label="delivery note"
															>
																<TableBody>
																	<TableRow>
																		{allowLinkQty === 1 && (
																			<TableCell>
																				<Grid container m={2}>
																					<Typography sx={{ width: "100%" }}>
																						<strong>Qty : </strong>
																					</Typography>
																					<Typography variant="body2">
																						{currentCaseObject.Data[cdtItem].Qty
																							? currentCaseObject.Data[cdtItem]
																									.Qty
																							: 0}
																					</Typography>
																				</Grid>
																			</TableCell>
																		)}

																		{allowLinkBox === 1 && (
																			<TableCell>
																				<Grid container m={2}>
																					<Typography sx={{ width: "100%" }}>
																						<strong>Linked Boxes:</strong>
																					</Typography>
																					<Typography variant="body2">
																						{currentCaseObject.Data[cdtItem]
																							.LinkedBoxes &&
																							currentCaseObject.Data[
																								cdtItem
																							].LinkedBoxes.map((item) => {
																								return `${Boxes[item]?.label}, `;
																							})}
																					</Typography>
																				</Grid>
																			</TableCell>
																		)}
																	</TableRow>
																</TableBody>
															</Table>
														</TableContainer>
													</Box>
												);
											})}
									</Stack>
								</Paper>
							</Stack>
						</Box>
						{/**-------------------------------------------------- */}
					</Grid>
				</Grid>
			)}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* Delivery note info here --------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}

			{dialogCurrentStep === "delivery_note" && (
				<Grid container padding={1} mb={2} ml={0.1} spacing={2}>
					{/* --------------------------3 COLUMN GRID-------------------------- */}

					<Grid item xs={4}>
						{/* <Paper>item1</Paper> */}
					</Grid>
					<Grid item xs={6}>
						{/* <Paper>item2</Paper> */}
					</Grid>
					<Grid item xs={2}>
						<Button
							sx={{ m: 2, color: "white" }}
							variant="contained"
							size="small"
							color="success"
							startIcon={<SaveAltOutlined />}
							onClick={handlePrint}
						>
							Export
						</Button>
					</Grid>
					{/* --------------------------3 COLUMN GRID END-------------------------- */}

					<Grid
						ref={componentRef}
						style={{ width: "100%" }}
						// style={{ width: "100%",height: window.innerHeight  }}
					>
						<Grid
							container
							// padding={1}
							display={"flex"}
							justifyContent={"space-between"}
							mt={2}
							ml={0}
							// spacing={2}
						>
							<Grid item xs={5}>
								<Typography component="h1" variant="h5" paddingY={1} m={3}>
									Delivery Note
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<img src={logo} width={250} height={55} alt="Logo" />
							</Grid>
							<Grid item xs={1}></Grid>
						</Grid>
						<Grid container padding={1} mb={2} ml={0.1} spacing={2}>
							<Grid item xs={6}>
								<Grid container direction={"column"}>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>Customer Name: </strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ mx: 6 }}>
													{Companies.filter(
														(comp) => comp.Guid === currentCaseObject.Company
													)[0]?.CompanyName || "N/A"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>Customer Address:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ mx: 4 }}>Address Here</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>Customer Contact:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ mx: 4 }}>Phone Number</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>Request Logged BY:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ mx: 3 }}>
													{currentCaseObject.CreatedBy || "N/A"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={4}>
								{/* <Paper>item1</Paper> */}
							</Grid>

							<Grid item xs={2}>
								<Grid container direction={"column"}>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>NR:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ mx: 3 }}>
													{Math.floor(Math.random() * 1677215).toString(8)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>Date:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ marginX: 2 }}>
													{new Date().toISOString().split("T")[0]}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item>
										<Grid container>
											<Grid item>
												<Typography>
													<strong>PO NR:</strong>
												</Typography>
											</Grid>
											<Grid item>
												<Typography sx={{ marginX: 1 }}>
													{currentCaseObject.PurchaseOrderNo || "N/A"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid container padding={1} mb={2} ml={0.1} spacing={2}>
							<Grid item xs={2}>
								<strong>Request Details : </strong>
							</Grid>
							<Grid item xs={9}>
								{currentCaseObject.RequestDetail}
							</Grid>
						</Grid>{" "}
						<Grid container padding={1} mb={2} ml={0.1} spacing={2}>
							<Grid item xs={2}>
								<strong>Additional Notes : </strong>
							</Grid>
							<Grid item xs={9}>
								{currentCaseObject.AdditionalNotes}
							</Grid>
						</Grid>
						<br />
						<Grid container padding={1} mb={2} ml={0.1} spacing={2}>
							<Table size="small">
								<TableHead
									sx={{
										fontWeight: "bold",
										backgroundColor: "#dddddd",
									}}
								>
									<TableRow>
										<TableCell sx={{ fontWeight: "bold" }}>
											Case Type Description
										</TableCell>
										<TableCell align="center" sx={{ fontWeight: "bold" }}>
											Qty
										</TableCell>
										<TableCell align="center" sx={{ fontWeight: "bold" }}>
											Linked Boxes
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* Case Type Description : */}
									{currentCaseObject.CaseDescriptionType &&
										currentCaseObject.CaseDescriptionType.map((cdtItem) => {
											let currentDescriptionType =
												getCaseDescriptionTypes.filter(
													(currItem) => currItem.Guid === cdtItem
												)[0];
											let allowLinkBox = 0;
											let allowLinkQty = 0;
											if (currentDescriptionType?.Data) {
												allowLinkBox =
													currentDescriptionType.Data?.AllowLinkBox || 0;
												allowLinkQty =
													currentDescriptionType.Data?.AllowQty || 0;
											}
											return (
												<TableRow key={cdtItem + "_CaseDescriptionType_Row"}>
													<TableCell>
														{currentDescriptionType.Name || "N/A"}
													</TableCell>
													<TableCell align="center">
														{currentCaseObject.Data[cdtItem].Qty
															? currentCaseObject.Data[cdtItem].Qty
															: ""}
													</TableCell>
													<TableCell align="center">
														{currentCaseObject.Data[cdtItem].LinkedBoxes &&
															currentCaseObject.Data[cdtItem].LinkedBoxes.map(
																(item) => {
																	return `${Boxes[item]?.label}, `;
																}
															)}
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</Grid>
						{/* New Table Format with Edit and Delete  */}
						{/* <Grid container m={2}>
              <Typography sx={{width:"100%"}}>
                <strong>Boxes linked to case:</strong>
              </Typography>
              <Typography variant="body2">
                {currentCaseObject.BoxLinked &&
                  currentCaseObject.BoxLinked.map((item) => {
                    return `${Boxes[item]?.label}, `;
                  })}
              </Typography>
            </Grid> */}
						<Grid container m={2} mt={5}>
							<Typography sx={{ mb: 2 }}>
								<strong>FOR OFFICE USE</strong>
							</Typography>
							<TableContainer component={Paper}>
								<Table
									sx={{
										minWidth: 850,
										minHeight: 200,
										backgroundColor: "grey",
									}}
									aria-label="delivery note"
								>
									<TableHead>
										{/* <TableRow>
                    <strong>NR Allocated To New Boxes: </strong>
                  </TableRow> */}
										<TableRow>
											<TableCell>
												<strong>Admin Name/ Signature & Date: </strong>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<strong>Driver Name / Signature & Date:</strong>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>
												<strong>Client Name / Signature & Date:</strong>
											</TableCell>
										</TableRow>
									</TableHead>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</Grid>
			)}

			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* New Order/case info here -------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}
			{/* --------------------------------------------------------------------------------------------------------------------------------------*/}

			{dialogCurrentStep === "new" && (
				<Paper>
					<Grid
						container
						mt={2}
						mb={2}
						paddingBottom={2}
						spacing={2}
						justifyContent={"center"}
						columns={{ xs: 1, sm: 2, md: 4 }}
					>
						{/* <SelectCompanies /> */}

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							display={"flex"}
							justifyContent={"center"}
						>
							{/* Customer Select --------------------------------------------------------------------------------*/}
							<Select
								defaultValue={0}
								label="Select Customer"
								variant="outlined"
								size="small"
								sx={{ width: "40%", mr: 2 }}
								value={currentCaseObject.Company || 0}
								onChange={(e) => onTextChange(e, "Company")}
							>
								<MenuItem size="small" value={0}>
									Select Customer
								</MenuItem>

								{Companies.map((companyItem) => {
									return (
										<MenuItem
											size="small"
											key={companyItem.Guid}
											value={companyItem.Guid}
										>
											{companyItem.CompanyName} ({companyItem.AccCode})
										</MenuItem>
									);
								})}
							</Select>
							{/* Purchase Order Number --------------------------------------------------------------------------------*/}
							<TextField
								label="PO Number"
								variant="outlined"
								size="small"
								sx={{ width: "40%" }}
								value={currentCaseObject.PurchaseOrderNo || ""}
								onChange={(e) => onTextChange(e, "PurchaseOrderNo")}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							display={"flex"}
							justifyContent={"center"}
						>
							{/* Case Type Select --------------------------------------------------------------------------------*/}
							<Select
								defaultValue={0}
								variant="outlined"
								size="small"
								sx={{ width: "40%", mr: 2 }}
								value={currentCaseObject.CaseType || 0}
								onChange={(e) => onTextChange(e, "CaseType")}
							>
								<MenuItem size="small" value={0}>
									Select Case Type
								</MenuItem>

								{getCaseTypes.map((getCaseTypesItem) => {
									return (
										<MenuItem
											size="small"
											key={getCaseTypesItem.Guid}
											value={getCaseTypesItem.Guid}
										>
											{getCaseTypesItem.Name}
										</MenuItem>
									);
								})}
							</Select>

							{/* Type Select --------------------------------------------------------------------------------*/}
							<Select
								defaultValue={0}
								variant="outlined"
								size="small"
								sx={{ width: "40%" }}
								value={currentCaseObject.Type || 0}
								onChange={(e) => onTextChange(e, "Type")}
							>
								<MenuItem size="small" value={0}>
									Type
								</MenuItem>
								{getTypes.map((typeItem) => {
									return (
										<MenuItem
											size="small"
											key={typeItem.Guid}
											value={typeItem.Guid}
										>
											{typeItem.TypeName}
										</MenuItem>
									);
								})}
							</Select>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							display={"flex"}
							justifyContent={"center"}
						>
							{/* Request Details Text Area --------------------------------------------------------------------------------*/}
							<TextField
								rows={4}
								multiline
								label="Request Details"
								variant="outlined"
								size="small"
								sx={{ width: "82%" }}
								value={
									currentCaseObject.RequestDetail
										? currentCaseObject.RequestDetail
										: ""
								}
								onChange={(e) => onTextChange(e, "RequestDetail")}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							display={"flex"}
							justifyContent={"center"}
						>
							{/* Request Details Text Area --------------------------------------------------------------------------------*/}
							<TextField
								rows={4}
								multiline
								label="Additional Notes"
								variant="outlined"
								size="small"
								sx={{ width: "82%" }}
								value={
									currentCaseObject.AdditionalNotes
										? currentCaseObject.AdditionalNotes
										: ""
								}
								onChange={(e) => onTextChange(e, "AdditionalNotes")}
							/>
						</Grid>

						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							display={"flex"}
							justifyContent={"center"}
						>
							{/* Service Type Select --------------------------------------------------------------------------------*/}
							<FormControl sx={{ width: "40%", mr: 2 }}>
								<InputLabel id="CaseTypeDesc_SelectLabel">
									Case Type Description
								</InputLabel>
								<Select
									labelId="CaseTypeDesc_SelectLabel"
									id="CaseTypeDesc_Select"
									defaultValue={0}
									size="small"
									value={serviceType || 0}
									onChange={handleServiceType}
									multiple
									input={<OutlinedInput label="Case Type Description" />}
								>
									<MenuItem size="small" value={0}>
										Case Type Description
									</MenuItem>

									{getCaseDescriptionTypes.map((STypeDescription) => {
										return (
											<MenuItem
												size="small"
												key={STypeDescription.Guid}
												value={STypeDescription.Guid}
											>
												{STypeDescription.Name}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>

							{/* Source Select --------------------------------------------------------------------------------*/}
							<Select
								defaultValue={0}
								variant="outlined"
								size="small"
								sx={{ width: "40%" }}
								value={currentCaseObject.Source || 0}
								onChange={(e) => onTextChange(e, "Source")}
							>
								<MenuItem size="small" value={0}>
									Source
								</MenuItem>
								{getSource.map((sourceItem) => {
									return (
										<MenuItem
											size="small"
											key={sourceItem.Guid}
											value={sourceItem.Guid}
										>
											{sourceItem.SourceName}
										</MenuItem>
									);
								})}
							</Select>
						</Grid>

						{/* Case Type Description : */}
						{currentCaseObject.CaseDescriptionType &&
							currentCaseObject.CaseDescriptionType.map((cdtItem) => {
								let currentDescriptionType = getCaseDescriptionTypes.filter(
									(currItem) => currItem.Guid === cdtItem
								)[0];
								let allowLinkBox =
									currentDescriptionType.Data.AllowLinkBox || 0;
								let allowLinkQty = currentDescriptionType.Data.AllowQty || 0;
								return (
									<Grid item xs={12} sm={6} md={3} key={cdtItem}>
										<Box marginY={2} key={cdtItem + "_CaseDescriptionType"}>
											<Typography variant="h6" textAlign={"center"}>
												{currentDescriptionType?.Name || "N/A"}
											</Typography>
											<Grid
												container
												direction="column"
												spacing={2}
												marginTop={1}
											>
												<Grid
													item
													xs={12}
													sm={6}
													md={3}
													display={"flex"}
													justifyContent={"center"}
												>
													{/* Linked Boxes Select --------------------------------------------------------------------------------*/}
													{allowLinkBox === 1 && (
														<FormControl sx={{ width: "40%", mr: 2 }}>
															<InputLabel id="BoxLinked_SelectLabel">
																Box Linked To Case
															</InputLabel>
															<Select
																labelId="BoxLinked_SelectLabel"
																id="BoxLinked_Select"
																multiple
																size="small"
																value={getLinkedBoxesText(cdtItem)}
																onChange={(e) => handleLinkBoxes(e, cdtItem)}
																input={
																	<OutlinedInput label="Box Linked To Case" />
																}
															>
																{Boxes.map((boxItem) => (
																	<MenuItem
																		key={boxItem.Guid}
																		value={boxItem.Guid}
																	>
																		{boxItem.label}
																	</MenuItem>
																))}
															</Select>
														</FormControl>
													)}

													{/* Qty Input --------------------------------------------------------------------------------*/}
													{allowLinkQty === 1 && (
														<TextField
															id="filled-number"
															label="Qty Ordered"
															type="number"
															size="small"
															InputLabelProps={{
																shrink: true,
															}}
															variant="outlined"
															sx={{ width: "40%" }}
															value={
																currentCaseObject.Data[cdtItem].Qty
																	? currentCaseObject.Data[cdtItem].Qty
																	: 0
															}
															onChange={(e) =>
																onTextDataChange(e, "Qty", cdtItem)
															}
														/>
													)}
												</Grid>
											</Grid>
										</Box>
									</Grid>
								);
							})}

						{/* Created By Select --------------------------------------------------------------------------------*/}
						{/* <TextField
                label="Created By"
                variant="outlined"
                size="small"
                sx={{ width: "40%", mr: 2 }}
                value={currentCaseObject.CreatedBy}
                onChange={(e) => onTextChange(e, "CreatedBy")}
              /> */}
						{/* Status Select --------------------------------------------------------------------------------*/}
						{/* <Select
                defaultValue={0}
                variant="outlined"
                size="small"
                sx={{ width: "40%" }}
                value={currentCaseObject.Status || 0}
                onChange={(e) => onTextChange(e, "Status")}
              >
                <MenuItem size="small" value={0}>
                  Status
                </MenuItem>
                {getStatus.map((statusItem) => {
                  return (
                    <MenuItem size="small" key={statusItem.Guid} value={statusItem.Guid}>
                      {statusItem.StatusName}
                    </MenuItem>
                  );
                })}
              </Select> */}
					</Grid>
				</Paper>
			)}
			{!dialogInner && (
				<Box display={"flex"} justifyContent={"center"}>
					<Button
						sx={{ m: 2 }}
						variant="contained"
						size="small"
						color="error"
						startIcon={<CancelOutlined />}
						onClick={(e) => {
							cancelNewCase(e);
						}}
					>
						Cancel
					</Button>
					<Button
						sx={{ m: 2 }}
						variant="contained"
						size="small"
						startIcon={<ArrowBackIosNewOutlined />}
						onClick={(e) => handlePostPo(e, true)}
					>
						Back
					</Button>
					<Button
						sx={{ m: 2, color: "white" }}
						variant="contained"
						size="small"
						color="success"
						startIcon={
							dialogCurrentStep === "new" ? (
								<ArrowForwardIosOutlined />
							) : (
								<SaveTwoTone />
							)
						}
						onClick={(e) => handlePostPo(e)}
					>
						{dialogCurrentStep === "new" && `Next`}
						{dialogCurrentStep === "summary" && `Save`}
						{dialogCurrentStep === "delivery_note" && `Save`}
					</Button>
				</Box>
			)}
		</>
	);
};

export default NewOrder;
