
const DPNotificationsEvents = {

    on(callback) {
        document.addEventListener("DPNotifications", (e) => {callback(e.detail)});
    },

    dispatch(message, type) {
        
        let newMessage = {
            "message":message, 
            "type":type==="success"||type==="warning"||type==="error"?type:"info",
            "seen":false,
            "id":Date.now(),
        }

        document.dispatchEvent(new CustomEvent("DPNotifications", { detail: newMessage }));
    },

    remove(callback) {
        document.removeEventListener("DPNotifications", callback);
    },
}

export default DPNotificationsEvents;