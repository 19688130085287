import React, { useMemo, useState } from "react";
import { useDPApi } from "../Context/DPApi";

import { Typography, Box, Paper, Button, Grid} from "@mui/material";
import { FormControl, OutlinedInput, InputAdornment, CircularProgress} from "@mui/material";
import { Toolbar, TableContainer, Table, TableHead, TableBody, TablePagination, TableRow, TableCell, TableSortLabel} from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions} from "@mui/material";

import { Search, AddCircleOutline} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";

import NewOrder from './NewOrder';
//===============================================================
const CasesPage = () => {
  const {CaseDetails} = useDPApi();
  const navigate = useNavigate();

  //db objects
  const {data:CasesData} = CaseDetails.useGetCases();
  const [casesData, setCasesData] = useState([]);

  //State Management
  // const [advanceSearchOpen, setAdvanceSearchOpen] = useState(false);
  const [filteredCaseData, setFilteredCaseData] = useState([]);
  const [quickSearchValue, setQuickSearchValue] = useState("");
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
  const [filteredSummaryData, setFilteredSummaryData] = useState([]);
  //controlled for warning
  if(filteredSummaryData){}

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const [showSelectedDialog, setShowSelectedDialog] = useState(false);
  const [selectedTableRow, setSelectedTableRow] = useState({});

  const headCells = [
    {
      id: "CreatedDate",
      numeric: false,
      disablePadding: true,
      label: "Created Date",
    },
    {
      id: "PurchaseOrderNo",
      numeric: false,
      disablePadding: true,
      label: "Ref Id",
    },
    {
      id: "RequestDetail",
      numeric: false,
      disablePadding: true,
      label: "Details",
    },
    {
      id: "Status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
  ];

  // ------------------------------------------------------------------------------------------------
  //  Load and Sanitize Data
  // ------------------------------------------------------------------------------------------------
  const sanitizeCaseData = () => {
    let tempData = [];
    console.log(CasesData)

    CasesData.map((caseRow) => {
      let tempCase = {};
      tempCase.Guid = caseRow.Guid;

      headCells.map((header) => {
        tempCase[header.id] = caseRow[header.id];
        return header;
      });

      //normalize dates
      if (tempCase.CaseCreatedDate) {
        tempCase.CaseCreatedDate = new Date(caseRow.CaseCreatedDate)
          .toISOString()
          .split("T")[0];
      }

      tempData.push(tempCase);
      return caseRow;
    });

    setFilteredSummaryData([...tempData]);
    setFilteredCaseData([...tempData]);
    setCasesData([...tempData]);
    setShowLoadingSpinner(false);
  };
  useMemo(() => {
    if(CasesData)
    {
      sanitizeCaseData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CasesData]);

  const handleAddNewOrder = () => {
    navigate('/NewOrder');
  };

  //------------------------------------------------------------------------------------------------
  // Handle table selection
  //------------------------------------------------------------------------------------------------
  const handleRowClick = (item) => {
    let selectedCase = CasesData.filter(caseRow => caseRow.Guid === item.Guid)[0];
    setSelectedTableRow(selectedCase);
    setShowSelectedDialog(true);
  };

  // ------------------------------------------------------------------------------------------------
  //  Search and Filter
  // ------------------------------------------------------------------------------------------------
  const handleQuickSearchChange = (event) => {
    setQuickSearchValue(event.target.value);

    //filter values here
    let tempDataArray = casesData.filter((caseRow) => {
      let canReturn = false;
      Object.keys(caseRow).map((key) => {
        if (
          key !== "Guid" &&
          String(caseRow[key])
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        ) {
          canReturn = true;
        }
        return caseRow;
      });
      return canReturn;
    });

    setFilteredCaseData([...tempDataArray]);
    setFilteredSummaryData([...tempDataArray]);
  };

  const HighlightText = (props) => {
    const { value } = props;

    if (quickSearchValue) {
      if (
        String(value).toLowerCase().includes(quickSearchValue.toLowerCase())
      ) {
        //construct the array
        let myTextArray = String(value)
          .toLowerCase()
          .split(quickSearchValue.toLowerCase());

        return (
          <div>
            {String(value).slice(0, myTextArray[0].length)}
            <Box sx={{ background: "#f07d06", display: "inline-flex" }}>
              {String(value).slice(
                myTextArray[0].length,
                myTextArray[0].length + quickSearchValue.length
              )}
            </Box>
            {String(value).slice(
              myTextArray[0].length + quickSearchValue.length
            )}
          </div>
        );
      }
    }

    return value;
  };

  // ------------------------------------------------------------------------------------------------
  //  Pagination
  // ------------------------------------------------------------------------------------------------
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // ------------------------------------------------------------------------------------------------
  //  Order and Sorting
  // ------------------------------------------------------------------------------------------------
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - casesData.length) : 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));


  //dialog controls
  const [editingDialog, setEditingDialog] = useState(false);
  const [viewingDialog, setViewingDialog] = useState(true);
  const [deliveryNoteDialog, setDeliveryNoteDialog] = useState(false);
  const changeDialogType = (showEditingDialog, showViewingDialog, showDeliveryNoteDialog) => {
    setEditingDialog(showEditingDialog);
    setViewingDialog(showViewingDialog);
    setDeliveryNoteDialog(showDeliveryNoteDialog);
  }

  const updateCurrentSelectedRowInDialog = (newObject) => {
    setSelectedTableRow({...newObject})
  }


  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Box>
      {/* <NewCase /> */}
      {/* <h4>borderline</h4> */}
      {/* case form was here */}
      <Dialog
        open={showSelectedDialog}
        onClose={(e) => setShowSelectedDialog(false)}
        maxWidth={"xl"}
        fullWidth
      >
        <DialogTitle>
          {selectedTableRow.CaseReferenceId} ({selectedTableRow.CaseStatus})
        </DialogTitle>
        <DialogContent>
            <NewOrder
              editing={editingDialog}
              viewDeliveryNote={deliveryNoteDialog}
              viewingDetails={viewingDialog}
              editingObject={selectedTableRow}
              dialogInner={true}
              updateCurrentObject={updateCurrentSelectedRowInDialog}
            />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={(e) => setShowSelectedDialog(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={(e) => setShowSelectedDialog(false)}
          >
            Request Update
          </Button>
          {
            viewingDialog || deliveryNoteDialog?
              <Button
                variant="contained"
                size="small"
                onClick={(e) => changeDialogType(true, false, false)}
              >
                Edit
              </Button>
              :
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {changeDialogType(false, true, false); CaseDetails.UpdatCaseDetail(selectedTableRow)}}
              >
                Save
              </Button>
          }
          
          <Button
            variant="contained"
            size="small"
            onClick={(e) => changeDialogType(false, false, true)}
          >
            Delivery Note
          </Button>
        </DialogActions>
      </Dialog>

      <Paper>
        <Box>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Cases
            </Typography>
            <FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
              {/* new case button start */}
              <Grid container spacing={2} mb={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    sx={{ m: 4 }}
                    size="small"
                    variant="contained"
                    startIcon={<AddCircleOutline />}
                    onClick={handleAddNewOrder}
                  >
                      New Order
                  </Button>
                </Grid>
              </Grid>
              {/* new case button end */}

              <OutlinedInput
                id="quickSearch_txt"
                variant="outlined"
                value={quickSearchValue}
                size="small"
                onChange={handleQuickSearchChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            {/* <Tooltip title="Advanced Search">
              <IconButton
                onClick={() => setAdvanceSearchOpen(!advanceSearchOpen)}
              >
                {advanceSearchOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Tooltip> */}
          </Toolbar>

          {/* <Collapse in={advanceSearchOpen} sx={{ marginBottom: 3 }}>
            <Box p={3}>
              <Typography variant="h6">Advanced Search</Typography>
            </Box>
          </Collapse> */}

          <TableContainer sx={{ padding: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id+"_Header"}
                      align={headCell.numeric ? "right" : "left"}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}

                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {showLoadingSpinner && (
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ padding: 3 }}>
                      <CircularProgress color="secondary" />
                    </TableCell>
                  </TableRow>
                )}

                {!showLoadingSpinner &&
                  filteredCaseData
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.Guid}
                          hover
                          tabIndex={-1}
                          onClick={(e) => handleRowClick(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          {headCells.map((headCell) => {
                            return (
                              <TableCell key={row.Guid + headCell.id}>
                                <HighlightText value={row[headCell.id] || ""} />
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      );
                    })}
                {!showLoadingSpinner && emptyRows > 0 && (
                  <StyledTableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={casesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default CasesPage;
