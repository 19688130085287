import React, { useState, useEffect, useMemo } from "react";
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import MaterialTable from "material-table";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Fade,
	FormControl,
	Grid,
	InputAdornment,
	Modal,
	OutlinedInput,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
// import jsPDF from 'jspdf';
import "jspdf-autotable";
import {
	Delete,
	Edit,
	Save,
	Search,
	AddCircleOutline,
	Close,
	AdminPanelSettings,
	SupervisorAccount,
	ToggleOn,
	ToggleOff,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import BoxDetails from "../Comps/BoxDetails";
import { useDPApi } from "../Context/DPApi";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
const downloadPdf = () => {};
// -------------------------------Companies Functions-------------------------

// -------------------------------Companies Functions-------------------------
function UsersPages() {
	const [filteredUserData, setFilteredUserData] = useState([]);
	const [quickSearchValue, setQuickSearchValue] = useState("");
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const [filteredSummaryData, setFilteredSummaryData] = useState([]);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const [selectedTableRow, setSelectedTableRow] = useState({});
	//----------------  Data from DPAPI  -----------------------------------

	const { CompanyInfo } = useDPApi();
	const { UsersInfo } = useDPApi();

	//-----------------  Invoke Get all data  ------------------------------

	const {
		data: companiesData,
		isSuccess: companiesLoaded,
		refetch: refetchCompanies,
	} = CompanyInfo.useGetCompanies();

	const {
		data: UsersData,
		isSuccess: UsersLoaded,
		refetch: refetchUsers,
	} = UsersInfo.useGetUsers();

	//-------------------  Pass data to states  ----------------------------

	const [getCompanies, setCompanies] = useState([]);

	const [getUsers, setUsers] = useState([]);

	//-------------------  sanitize data  ----------------------------

	const sanitizeCompaniesData = () => {
		let tempData = companiesData;
		setCompanies([...tempData]);
	};

	const sanitizeUsersData = () => {
		let tempData = UsersData;
		setUsers([...tempData]);
	};

	//-------------------  Invoke API data on render   ----------------------------

	useEffect(() => {
		if (companiesData) {
			sanitizeCompaniesData();
		}
	}, [companiesData, companiesLoaded]);

	useEffect(() => {
		if (UsersData) {
			sanitizeUsersData();
			// console.log(UsersData);
		}
	}, [UsersData, UsersLoaded]);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------
	//-- Destructered constant data  --
	//------------------------------------------------------------------------------------------------

	const [currentUsersObject, setCurrentUsersObject] = useState({});
	useMemo(() => {
		let NewUsersObject = UsersInfo.UsersTemplate;

		NewUsersObject.CompanyId = "";
		NewUsersObject.FirstName = "";
		NewUsersObject.LastName = "";
		NewUsersObject.UserName = "";
		NewUsersObject.Email = "";
		NewUsersObject.Tel = "";
		NewUsersObject.InternalUser = "";
		NewUsersObject.Active = "";
		NewUsersObject.Password = "";
		NewUsersObject.ForceChangePassword = "";
		NewUsersObject.GlobalAdmin = "";

		setCurrentUsersObject({ ...NewUsersObject });
	}, []);

	const headCells = [
		{
			id: "CompanyId",
			numeric: false,
			disablePadding: true,
			label: "Company",
		},
		{
			id: "FirstName",
			numeric: false,
			disablePadding: true,
			label: "First Name",
		},
		{
			id: "LastName",
			numeric: false,
			disablePadding: true,
			label: "Last Name",
		},
		{
			id: "Email",
			numeric: false,
			disablePadding: true,
			label: "Email",
		},
		{
			id: "Tel",
			numeric: false,
			disablePadding: true,
			label: "Phone Number",
		},

		{
			id: "InternalUser",
			numeric: false,
			disablePadding: true,
			label: "Role",
		},
		{
			id: "Active",
			numeric: false,
			disablePadding: true,
			label: "Status",
		},
	];

	//------------------------------------------------------------------------------------------------
	// Handle Users onText Change
	//------------------------------------------------------------------------------------------------

	const onTextChange = (e, key, type = "text") => {
		// console.log(e, key);
		if (type === "text") {
			let tempObject = { ...currentUsersObject };
			tempObject[key] = e.target.value;
			setCurrentUsersObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentUsersObject };
			tempObject[key] = e.target.value;
			setCurrentUsersObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentUsersObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentUsersObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentUsersObject };
			tempObject[key] = e.target.value;
			setCurrentUsersObject({ ...tempObject });
		}
	};

	//-------------------------------------------------------------
	//        Handle Create Users Data
	//---------------------------------------------------------------
	const handlePostUsers = (e) => {
		e.preventDefault();
		// console.log(currentUsersObject);
		let createUser = { ...currentUsersObject };
		UsersInfo.AddUser(createUser);
		setOpen(false);
	};

	//-------------------------------------------------------------
	//        Handle Update users Data
	//---------------------------------------------------------------
	const handleUpdateUsers = (e) => {
		e.preventDefault();
		// console.log(currentUsersObject);
		let updateUser = { ...currentUsersObject };
		UsersInfo.UpdateUser(updateUser);
		refetchUsers();
		setEditOpen(false);
	};

	//-------------------------------------------------------------
	//        Prefil Users Data on Edit
	//---------------------------------------------------------------
	const [editingUser, setEditingUser] = useState(false);

	const handleEditing = () => {
		setEditingUser(!editingUser);
		setEditOpen(true);
		setShowSelectedDialog(false);
	};
	useEffect(() => {
		if (editingUser) {
			setCurrentUsersObject({ ...selectedTableRow });
		}
	}, [editingUser]);

	const updateCurrentObject = (newUsersObject) => {
		setSelectedTableRow({ ...newUsersObject });
	};

	useEffect(() => {
		if (typeof updateCurrentObject == "function") {
			updateCurrentObject(currentUsersObject);
		}
	}, [currentUsersObject]);

	//-------------------------------------------------------------
	//        Handle Delete Company Data
	//---------------------------------------------------------------
	const handleDeleteUser = (e) => {
		e.preventDefault();
		let deleteUser = { ...currentUsersObject };
		console.log(UsersInfo);
		UsersInfo.DeleteUser(deleteUser.Guid);
		setOpen(false);
		setShowSelectedDialog(false);
	};

	// ------------------------------------------------------------------------------------------------
	//  Load and Sanitize company Data
	// ------------------------------------------------------------------------------------------------
	const sanitizeUserData = () => {
		let tempData = [];
		// console.log(UsersData);

		UsersData.map((UserRow) => {
			let tempUsers = {};
			tempUsers.Guid = UserRow.Guid;

			headCells.map((header) => {
				tempUsers[header.id] = UserRow[header.id];
				return header;
			});

			//normalize dates
			if (tempUsers.CreatedDate) {
				tempUsers.CreatedDate = new Date(UserRow.CreatedDate)
					.toISOString()
					.split("T")[0];
			}

			tempData.push(tempUsers);
			return UserRow;
		});

		// setFilteredSummaryData([...tempData]);
		setFilteredUserData([...tempData]);
		setUsers([...tempData]);
		setShowLoadingSpinner(false);
	};

	useMemo(() => {
		if (UsersData) {
			sanitizeUserData();
		}
	}, [UsersData]);

	const [showSelectedDialog, setShowSelectedDialog] = useState(false);

	const [editOpen, setEditOpen] = useState(false);
	const handleEditOpen = () => {
		setEditOpen(true);
	};
	const handleEditClose = () => {
		setEditOpen(false);
	};
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	//controlled for warning
	if (filteredSummaryData) {
	}

	//------------------------------------------------------------------------------------------------
	// Handle table selection
	//------------------------------------------------------------------------------------------------

	const handleRowClick = (item) => {
		let selectedUsers = UsersData.filter(
			(UserRow) => UserRow.Guid === item.Guid
		)[0];
		setSelectedTableRow(selectedUsers);
		setShowSelectedDialog(true);
	};

	// ------------------------------------------------------------------------------------------------
	//  Search and Filter
	// ------------------------------------------------------------------------------------------------
	const handleQuickSearchChange = (event) => {
		setQuickSearchValue(event.target.value);

		//filter values here
		let tempDataArray = getUsers.filter((UserRow) => {
			let canReturn = false;
			Object.keys(UserRow).map((key) => {
				if (
					key !== "Guid" &&
					String(UserRow[key])
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				) {
					canReturn = true;
				}
				return UserRow;
			});
			return canReturn;
		});

		setFilteredUserData([...tempDataArray]);
		setFilteredSummaryData([...tempDataArray]);
	};

	const HighlightText = (props) => {
		const { value } = props;

		if (quickSearchValue) {
			if (
				String(value).toLowerCase().includes(quickSearchValue.toLowerCase())
			) {
				//construct the array
				let myTextArray = String(value)
					.toLowerCase()
					.split(quickSearchValue.toLowerCase());

				return (
					<div>
						{String(value).slice(0, myTextArray[0].length)}
						<Box sx={{ background: "#f07d06", display: "inline-flex" }}>
							{String(value).slice(
								myTextArray[0].length,
								myTextArray[0].length + quickSearchValue.length
							)}
						</Box>
						{String(value).slice(
							myTextArray[0].length + quickSearchValue.length
						)}
					</div>
				);
			}
		}

		return value;
	};

	// ------------------------------------------------------------------------------------------------
	//  Pagination
	// ------------------------------------------------------------------------------------------------
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ------------------------------------------------------------------------------------------------
	//  Order and Sorting
	// ------------------------------------------------------------------------------------------------
	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getUsers.length) : 0;

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}));

	//------------------------------------------------------------------------------------------------
	// Handle new form popup
	//------------------------------------------------------------------------------------------------

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 800,
		bgcolor: "background.paper",
		borderRadius: "8px",
		boxShadow: 24,
		p: 4,
	};

	return (
		<Box>
			{/* ------------------ Companies Form-------------------------------- */}
			<Paper>
				<Box>
					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
						}}
					>
						<Typography
							sx={{ flex: "1 1 100%" }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							Users
						</Typography>
						<FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
							{/* new case button start */}
							<Grid container spacing={2} mb={2} justifyContent="flex-end">
								<Grid item>
									<Button
										sx={{ m: 4 }}
										size="small"
										variant="contained"
										startIcon={<AddCircleOutline />}
										onClick={handleOpen}
									>
										New User
									</Button>
								</Grid>
							</Grid>
							{/* new case button end */}

							<OutlinedInput
								id="quickSearch_txt"
								variant="outlined"
								value={quickSearchValue}
								size="small"
								onChange={handleQuickSearchChange}
								endAdornment={
									<InputAdornment position="end">
										<Search />
									</InputAdornment>
								}
							/>
						</FormControl>
					</Toolbar>

					<TableContainer sx={{ padding: 2 }}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id + "_Header"}
											align={headCell.numeric ? "right" : "left"}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : "asc"}
												onClick={createSortHandler(headCell.id)}
											>
												{headCell.label}

												{orderBy === headCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{showLoadingSpinner && (
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ padding: 3 }}>
											<CircularProgress color="secondary" />
										</TableCell>
									</TableRow>
								)}

								{!showLoadingSpinner &&
									filteredUserData
										.slice()
										.sort(getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<StyledTableRow
													key={row.Guid}
													align="left"
													hover
													tabIndex={-1}
													onClick={(e) => handleRowClick(row)}
													sx={{ cursor: "pointer" }}
												>
													{headCells.map((headCell) => {
														if (headCell.id === "Active") {
															return (
																<TableCell
																	align="left"
																	key={row.Guid + headCell.id}
																>
																	{row[headCell.id] === 1 ? (
																		<Chip
																			color="success"
																			label="Active"
																			icon={<ToggleOn />}
																		/>
																	) : (
																		<Chip
																			color="error"
																			label="Inactive"
																			icon={<ToggleOff />}
																		/>
																	)}
																</TableCell>
															);
														}
														if (headCell.id === "InternalUser") {
															return (
																<TableCell
																	align="left"
																	key={row.Guid + headCell.id}
																>
																	{row[headCell.id] === 1 ? (
																		<Chip
																			color="secondary"
																			// sx={{
																			// 	color: "black",
																			// 	background: "#2196f3",
																			// }}
																			label="Admin"
																			icon={
																				<AdminPanelSettings color="secondary" />
																			}
																		/>
																	) : (
																		<Chip
																			color="info"
																			label="User"
																			icon={<SupervisorAccount />}
																		/>
																	)}
																</TableCell>
															);
														}

														return (
															<TableCell
																align="left"
																key={row.Guid + headCell.id}
															>
																<HighlightText value={row[headCell.id] || ""} />
															</TableCell>
														);
													})}
												</StyledTableRow>
											);
										})}
								{!showLoadingSpinner && emptyRows > 0 && (
									<StyledTableRow
										align="left"
										style={{
											height: 33 * emptyRows,
										}}
									>
										<TableCell align="left" colSpan={6} />
									</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component="div"
						count={getUsers.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Box>
			</Paper>

			{/* ------------------ Users Dialog Box-------------------------------- */}

			<Dialog
				open={showSelectedDialog}
				onClose={(e) => setShowSelectedDialog(false)}
				maxWidth={"lg"}
			>
				<DialogTitle>
					{/* {selectedTableRow.CompanyId}  */}
					{selectedTableRow.FirstName} {selectedTableRow.LastName}
					{/* Details */}
				</DialogTitle>
				<DialogContent>
					<Paper>
						<Table
							sx={{ minWidth: 1050 }}
							size="small"
							aria-label="delivery note"
						>
							<TableHead
								sx={{
									display: "table-header-group",
									backgroundColor: "#dddddd;",
								}}
							>
								<TableRow sx={{ m: 1 }} align="center">
									{/* <TableCell align="center">
										<strong>Name</strong>
									</TableCell> */}

									<TableCell align="center">
										<strong> Phone number</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Password</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Change Password</strong>
									</TableCell>

									<TableCell align="center">
										<strong> Global Admin</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Status</strong>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.Tel || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.Password || "N/A"}
											{/* {currentUsersObject.Password || "N/A"} */}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.ForceChangePassword || "N/A"}
										</Typography>
									</TableCell>

									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.GlobalAdmin === 0 ? (
												<Chip
													icon={<AdminPanelSettings />}
													label="YES"
													color="secondary"
												/>
											) : (
												<Chip
													icon={<SupervisorAccount />}
													label="NO"
													color="primary"
												/>
											)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.Active === 1 ? (
												<Chip
													icon={<ToggleOn />}
													label="Active"
													color="success"
												/>
											) : (
												<Chip
													icon={<ToggleOff />}
													label="Inactive"
													color="error"
												/>
											)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						size="small"
						startIcon={<Edit />}
						onClick={(e) => handleEditing(true)}
					>
						Edit
					</Button>
					<Button
						variant="contained"
						size="small"
						color="error"
						startIcon={<Delete />}
						onClick={handleDeleteUser}
					>
						Delete
					</Button>
					<Button
						variant="contained"
						size="small"
						startIcon={<Close />}
						onClick={(e) => setShowSelectedDialog()}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			{/* ---------------------------DIALOG INPUT FORM----------------------------------- */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Divider>Users Detail Form</Divider>
						<Box>
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={2}
								>
									<TextField
										// Enable
										// id="outlined-disabled"
										label="First Name"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.FirstName || ""}
										onChange={(e) => onTextChange(e, "FirstName")}
									/>
									<TextField
										// id="outlined-password-input"
										label="Last name"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.LastName || ""}
										onChange={(e) => onTextChange(e, "LastName")}
									/>
									<TextField
										// id="outlined-password-input"
										label="UserName"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.UserName || ""}
										onChange={(e) => onTextChange(e, "UserName")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// required
										// id="outlined-required"
										type="text"
										label="Company"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.CompanyId || ""}
										onChange={(e) => onTextChange(e, "CompanyId")}
									/>

									<TextField
										// id="outlined-read-only-input"
										label="Phone Number"
										// type="number"
										size="small"
										type="text"
										sx={{ width: "50%" }}
										value={currentUsersObject.Tel || ""}
										onChange={(e) => onTextChange(e, "Tel")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-search"
										label="Email Address"
										type="Email"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.Email || ""}
										onChange={(e) => onTextChange(e, "Email")}
									/>

									<TextField
										// id="outlined-number"
										label="Status"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.Active || ""}
										onChange={(e) => onTextChange(e, "Active")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Global Admin"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.GlobalAdmin || ""}
										onChange={(e) => onTextChange(e, "GlobalAdmin")}
									/>
									<TextField
										// id="outlined-helperText"
										label="Password"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.Password || ""}
										onChange={(e) => onTextChange(e, "Password")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Change Password"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.ForceChangePassword || ""}
										onChange={(e) => onTextChange(e, "ForceChangePassword")}
									/>

									<TextField
										// id="outlined-number"
										label="Role"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.InternalUser || ""}
										onChange={(e) => onTextChange(e, "InternalUser")}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										variant="contained"
										// disabled
										size="small"
										startIcon={<Save />}
										onClick={handlePostUsers}
									>
										Save
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>

			{/* ------------------Modal on Edit--------------------------- */}

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={editOpen}
				onClose={handleEditClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={editOpen}>
					<Box sx={style}>
						<Divider>Users Detail Form</Divider>
						<Box>
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// Enable
										// id="outlined-disabled"
										label="First Name"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.FirstName || ""}
										onChange={(e) => onTextChange(e, "FirstName")}
									/>
									<TextField
										// id="outlined-password-input"
										label="Last name"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.LastName || ""}
										onChange={(e) => onTextChange(e, "LastName")}
									/>
									<TextField
										// id="outlined-password-input"
										label="UserName"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.UserName || ""}
										onChange={(e) => onTextChange(e, "UserName")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// required
										// id="outlined-required"
										type="text"
										label="Company"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.CompanyId || ""}
										onChange={(e) => onTextChange(e, "CompanyId")}
									/>

									<TextField
										// id="outlined-read-only-input"
										label="Phone Number"
										// type="number"
										size="small"
										type="text"
										sx={{ width: "50%" }}
										value={currentUsersObject.Tel || ""}
										onChange={(e) => onTextChange(e, "Tel")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-search"
										label="Email Address"
										type="Email"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.Email || ""}
										onChange={(e) => onTextChange(e, "Email")}
									/>

									<TextField
										// id="outlined-number"
										label="Status"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.Active || ""}
										onChange={(e) => onTextChange(e, "Active")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Global Admin"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.GlobalAdmin || ""}
										onChange={(e) => onTextChange(e, "GlobalAdmin")}
									/>
									<TextField
										// id="outlined-helperText"
										label="Password"
										size="small"
										sx={{ width: "50%" }}
										value={currentUsersObject.Password || ""}
										onChange={(e) => onTextChange(e, "Password")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Change Password"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.ForceChangePassword || ""}
										onChange={(e) => onTextChange(e, "ForceChangePassword")}
									/>

									<TextField
										// id="outlined-number"
										label="Role"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentUsersObject.InternalUser || ""}
										onChange={(e) => onTextChange(e, "InternalUser")}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										variant="contained"
										// disabled
										size="small"
										startIcon={<Save />}
										onClick={handleUpdateUsers}
									>
										Update
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
}
export default UsersPages;
