import React from "react";

import { Stack } from "@mui/material";
import logo from "../Logo.jpg";

const AppLogo = (props) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={0}
      sx={{ height: 55, backgroundColor: "primary.main" }}
    >
      {/* <Typography variant="h5" sx={{ color: "#f07d06" }}>
        DMST
      </Typography>
      <Typography variant="h5" sx={{ color: "#FFF" }}>
        Portal
      </Typography> */}
      <img src={logo} width={250} height={55} alt="Logo" />
    </Stack>
  );
};

export { AppLogo };
