import React, { useMemo, useState, useEffect } from "react";
import { useDPApi } from "../Context/DPApi";

import {
	Collapse,
	Container,
	Typography,
	Box,
	Paper,
	List,
	MenuItem,
	TextField,
	Grid,
	Button,
	Select,
	InputLabel,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
} from "@mui/material";
import {
	FormControl,
	OutlinedInput,
	InputAdornment,
	CircularProgress,
} from "@mui/material";
import {
	Toolbar,
	IconButton,
	Tooltip,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TablePagination,
	TableRow,
	TableCell,
	TableSortLabel,
} from "@mui/material";

import {
	Search,
	ExpandMore,
	ExpandLess,
	Save,
	Edit,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
import BoxesPage from "../Pages/Boxes";

const BoxDetails = (props) => {
	//State Management
	const CHARACTER_LIMIT = 8;
	const { boxObject, IsEditing, updateCurrentBoxObject } = props;
	const [advanceSearchOpen, setAdvanceSearchOpen] = useState(false);
	const [linesData, setLinesData] = useState([]);
	const [boxesData, setBoxesData] = useState([]);
	const [filteredLinesData, setFilteredLinesData] = useState([]);
	const [quickSearchValue, setQuickSearchValue] = useState("");
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);
	const [poObject, setPOObject] = useState({});

	const [selectedLineObject, setSelectedLineObject] = useState("N/A");
	const [rowDetailsExpanded, setRowDetailsExpanded] = useState("N/A");

	//----------------  Data from DPAPI  -----------------------------------

	const { CompanyInfo } = useDPApi();
	const { BoxInfo } = useDPApi();
	const { LinesInfo } = useDPApi();
	//-----------------  Invoke Get all data  ------------------------------

	const { data: companiesData } = CompanyInfo.useGetCompanies();
	const { data: BoxInfoData, isSuccess: BoxLoaded } = BoxInfo.useGetBox(
		boxObject.BoxGuid
	);
	const { data: LinesInfoData } = LinesInfo.useGetLines();

	//-------------------  Pass data to states  ----------------------------

	const [Companies, setCompanies] = useState([]);
	const [getBoxes, setBoxes] = useState([]);
	const [getLines, setLines] = useState([]);

	//-------------------  sanitize data  ----------------------------

	const sanitizeCompaniesData = () => {
		let tempData = companiesData;
		setCompanies([...tempData]);
	};

	const sanitizeBoxData = () => {
		let tempData = BoxInfoData;

		setBoxes({ ...tempData });
	};

	const sanitizeLinesData = () => {
		let tempData = LinesInfoData;
		// console.log(LinesInfoData);
		setLines([...tempData]);
	};
	//-------------------  Invoke API data on render   ----------------------------

	useEffect(() => {
		if (companiesData) {
			sanitizeCompaniesData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companiesData]);

	useEffect(() => {
		if (BoxLoaded) {
			if (BoxInfoData) {
				sanitizeBoxData();
			}
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BoxInfoData, BoxLoaded]);

	useEffect(() => {
		if (LinesInfoData) {
			sanitizeLinesData();
		}
	}, [LinesInfoData]);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------
	//-- Destructered constant data  --
	//------------------------------------------------------------------------------------------------

	const [currentBoxObject, setCurrentBoxObject] = useState({});
	useMemo(() => {
		let NewBoxObject = BoxInfo.BoxTemplate;

		NewBoxObject.BoxBoxesId = "";
		NewBoxObject.BoxGuid = "";
		NewBoxObject.BoxCreatedBy = "";
		NewBoxObject.BoxCreatedDate = "";
		NewBoxObject.BoxUpdatedBy = "";
		NewBoxObject.BoxUpdatedDate = "";
		NewBoxObject.BoxTimeStamp = "";
		NewBoxObject.BoxDeleted = "";
		NewBoxObject.BoxSecterr = " ";
		NewBoxObject.BoxName = "";
		NewBoxObject.BoxWorkflowId = "";
		NewBoxObject.BoxStatus = " ";
		NewBoxObject.BoxUserId = "";
		NewBoxObject.BoxChannelId = "";
		NewBoxObject.BoxBoxNumber = "";
		NewBoxObject.BoxDateRangeFrom = "";
		NewBoxObject.BoxDateRangeTo = "";
		NewBoxObject.BoxDescription = "";
		NewBoxObject.BoxLineNumber = "";
		NewBoxObject.BoxLineSubAccount = "";
		NewBoxObject.BoxLineSubSubAccount = "";
		NewBoxObject.BoxLineDescription = "";
		NewBoxObject.BoxLineKeyword = "";
		NewBoxObject.BoxLineRangeFrom = "";
		NewBoxObject.BoxLineRangeTo = "";
		NewBoxObject.BoxLineDateFrom = "";
		NewBoxObject.BoxLineDateTo = "";
		NewBoxObject.BoxCheckedBy = "";
		NewBoxObject.BoxDateTimeChecked = "";
		NewBoxObject.BoxLineEnteredBy = "";
		NewBoxObject.BoxLineCheckedBy = "";
		NewBoxObject.BoxDateLineEntered = "";
		NewBoxObject.BoxDateLineChecked = "";
		NewBoxObject.BoxLineCheckingErrors = "";
		NewBoxObject.BoxCompanyid = "";
		NewBoxObject.BoxPersonid = "";
		NewBoxObject.BoxNumberEntries = "";
		NewBoxObject.BoxKeyword = "";
		NewBoxObject.BoxDateBoxReceived = "";
		NewBoxObject.BoxStage = "";
		NewBoxObject.BoxDeclineComments = "";
		NewBoxObject.BoxDateDestruction = "";
		NewBoxObject.BoxCaptureController = "";
		NewBoxObject.BoxDateTimetoCapturing = "";
		NewBoxObject.BoxDatetimedistrcapturing = "";
		NewBoxObject.BoxReferringCaseId = "";
		NewBoxObject.BoxUserCapturer = "";
		NewBoxObject.BoxUserChecker = "";
		NewBoxObject.BoxDateToChecking = "";
		NewBoxObject.BoxOrderNumber = "";
		NewBoxObject.BoxFinanceUser = "";
		NewBoxObject.BoxDateToFinance = "";
		NewBoxObject.BoxPercentageLinesAdd = "";
		NewBoxObject.BoxPrimaryCaseId = "";
		NewBoxObject.BoxRemovecase = "";
		NewBoxObject.BoxDummyline = "";
		NewBoxObject.BoxDestructiondate = "";
		NewBoxObject.BoxOrderDate = "";
		NewBoxObject.BoxLocalBoxRef = "";
		NewBoxObject.BoxRetentionSchedule = "";
		NewBoxObject.BoxLegalHoldsDescription = "";
		NewBoxObject.BoxSafe = "";
		NewBoxObject.BoxLocation = "";
		NewBoxObject.BoxBranch = "";
		NewBoxObject.BoxProcessingLocation = "";
		NewBoxObject.BoxCostCentre = "";

		// console.log(NewBoxObject);
		setCurrentBoxObject({ ...NewBoxObject });
	}, []);

	const headCells = [
		{
			id: "LinesDescription",
			numeric: false,
			disablePadding: true,
			label: "Description",
		},
		{
			id: "LinesKeyword",
			numeric: false,
			disablePadding: true,
			label: "Keyword",
		},
		{
			id: "LinesDatefrom",
			numeric: false,
			disablePadding: true,
			label: "Date From",
		},
		{
			id: "LinesDateto",
			numeric: false,
			disablePadding: true,
			label: "Date To",
		},
		{
			id: "LinesLocalLinesReference",
			numeric: false,
			disablePadding: true,
			label: "Local Ref",
		},
		{
			id: "LinesCreatedDate",
			numeric: false,
			disablePadding: true,
			label: "Created Date",
		},
	];

	const [dialogCurrentStep, setDialogCurrentStep] = useState("new"); // edit
	//------------------------------------------------------------------------------------------------
	// Handle onText Change
	//------------------------------------------------------------------------------------------------

	const onTextChange = (e, key, type = "text") => {
		console.log(e, key);
		if (type === "text") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentBoxObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentBoxObject };
			tempObject[key] = e.target.value;
			setCurrentBoxObject({ ...tempObject });
		}
	};

	// useEffect(() => {
	// 	if (typeof updateCurrentBoxObject == "function") {
	// 		updateCurrentBoxObject(currentBoxObject);
	// 	}
	// }, [currentBoxObject]);

	const handlePostUpdate = (e) => {
		e.preventDefault();

		// console.log(currentBoxObject);
		let postingObject = { ...currentBoxObject };
		BoxInfo.UpdateBox(postingObject.BoxGuid, postingObject);
		// BoxInfo.AddBox(postingObject);

		setIsEditing(!IsEditing);
	};
	//  const loadNewOrEdit = () => {
	// 	setCurrentBoxObject({ ...tempObject });
	//  };

	// ------------------------------------------------------------------------------------------------
	//  Load and Sanitize Data
	// ------------------------------------------------------------------------------------------------
	const sanitizeLineData = () => {
		let tempData = [];
		// console.log(LinesInfoData);
		// console.log(getBoxes);
		// console.log(getLines);

		// getBoxes.Lines &&
		getBoxes.Box &&
			// getBoxes.Lines.map((line) => {
			getLines.map((line) => {
				line.Line = line;
				// console.log(line);
				let tempLine = {};
				//tempLine.BoxGuid = line.Line.LinesLinesId;
				tempLine.BoxBoxesId = line.LinesLinesId;
				tempLine.Guid = line.Guid;

				headCells.map((header) => {
					tempLine[header.id] = line.Line[header.id];
					return header;
				});

				//normalize dates
				if (tempLine.LinesCreatedDate) {
					tempLine.LinesCreatedDate = new Date(line.Line.LinesCreatedDate)
						.toISOString()
						.split("T")[0];
				}
				if (tempLine.LinesDatefrom) {
					tempLine.LinesDatefrom = new Date(line.Line.LinesDatefrom)
						.toISOString()
						.split("T")[0];
				}
				if (tempLine.LinesDateto) {
					tempLine.LinesDateto = new Date(line.Line.LinesDateto)
						.toISOString()
						.split("T")[0];
				}

				tempLine.LibraryList = line.LibraryList;
				tempData.push(tempLine);

				console.log(tempData);

				return line;
			});

		setFilteredLinesData([...tempData]);
		setBoxesData([...tempData]);
		setShowLoadingSpinner(false);
	};
	useMemo(() => {
		if (getBoxes) {
			sanitizeLineData();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getBoxes]);

	// ------------------------------------------------------------------------------------------------
	//  Search and Filter
	// ------------------------------------------------------------------------------------------------
	const handleQuickSearchChange = (event) => {
		setQuickSearchValue(event.target.value);

		//filter values here
		let tempDataArray = boxesData.filter((line) => {
			let canReturn = false;
			Object.keys(line).map((key) => {
				if (
					key !== "BoxGuid" &&
					String(line[key])
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				) {
					canReturn = true;
				}
				return line;
			});
			return canReturn;
		});

		setFilteredLinesData([...tempDataArray]);
	};

	const HighlightText = (props) => {
		const { value } = props;

		if (quickSearchValue) {
			if (
				String(value).toLowerCase().includes(quickSearchValue.toLowerCase())
			) {
				//construct the array
				let myTextArray = String(value)
					.toLowerCase()
					.split(quickSearchValue.toLowerCase());

				return (
					<div>
						{String(value).slice(0, myTextArray[0].length)}
						<Box sx={{ background: "#f07d06", display: "inline-flex" }}>
							{String(value).slice(
								myTextArray[0].length,
								myTextArray[0].length + quickSearchValue.length
							)}
						</Box>
						{String(value).slice(
							myTextArray[0].length + quickSearchValue.length
						)}
					</div>
				);
			}
		}

		return value;
	};

	//------------------------------------------------------------------------------------------------
	// Handle onText Change
	//------------------------------------------------------------------------------------------------

	// const onTextChange = (e, key, type = "text") => {
	//     console.log(e, key);
	//     if (type === "text") {
	//       let tempObject = { ...poObject };
	//       tempObject[key] = e.target.value;
	//       setPOObject({ ...tempObject });
	//     }

	//     if (type === "select") {
	//       let tempObject = { ...poObject };
	//       tempObject[key] = e.target.value;
	//       setPOObject({ ...tempObject });
	//     }

	//     if (type === "switch") {
	//       let tempObject = { ...poObject };
	//       tempObject[key] = e.target.isChecked ? 1 : 0;
	//       setPOObject({ ...tempObject });
	//     }
	//     if (type === "autocomplete") {
	//       let tempObject = { ...poObject };
	//       tempObject[key] = e.target.value;
	//       setPOObject({ ...tempObject });
	//     }
	//   };

	//------------------------------------------------------------------------------------------------
	// Handle table selection
	//------------------------------------------------------------------------------------------------
	const handleRowClick = (item) => {
		//dummy selectedline usage
		if (selectedLineObject) {
		}
		setSelectedLineObject(item);
	};

	// ------------------------------------------------------------------------------------------------
	//  Pagination
	// ------------------------------------------------------------------------------------------------
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ------------------------------------------------------------------------------------------------
	//  Order and Sorting
	// ------------------------------------------------------------------------------------------------
	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - linesData.length) : 0;

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}));

	//control editing
	const [isEditing, setIsEditing] = useState(false);
	useMemo(() => {
		setIsEditing(IsEditing);
	}, [IsEditing]);

	useEffect(() => {
		if (isEditing) {
			setCurrentBoxObject({ ...boxObject });
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	return (
		<Container disableGutters>
			<Paper>
				<Box>
					{/* --------------Form Start Here-------------------- */}

					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
						}}
					>
						<IconButton onClick={() => setIsEditing(!isEditing)}>
							{isEditing ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
					</Toolbar>

					<Divider></Divider>

					<Collapse in={isEditing} sx={{ marginBottom: 3 }}>
						<Box p={3}>
							<Divider>Box Detail Form</Divider>
							{/* <Typography variant='h6' sx={{ color: 'black',textAlign:"center" }}>Box Detail Form</Typography> */}

							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										label="Box Number"
										variant="outlined"
										size="small"
										inputProps={{
											maxlength: CHARACTER_LIMIT,
										}}
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxBoxNumber}
										onChange={(e) => onTextChange(e, "BoxBoxNumber")}
									/>

									<TextField
										label="Order Number"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxOrderNumber}
										onChange={(e) => onTextChange(e, "BoxOrderNumber")}
									/>
								</Grid>
								{/**--------Grid 2------------- */}

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										id="filled-number"
										label="Box Received Date"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxDateBoxReceived}
										onChange={(e) => onTextChange(e, "BoxDateBoxReceived")}
									/>

									<TextField
										label="Local Box Ref"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={currentBoxObject.BoxLocalBoxRef}
										onChange={(e) => onTextChange(e, "BoxLocalBoxRef")}
									/>
								</Grid>

								{/* -------------Grid 3------------- */}
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<Select
										defaultValue={0}
										label="Select Customer"
										variant="outlined"
										size="small"
										sx={{ width: "50%" }}
										value={poObject.BoxCompanyId}
										onChange={(e) => onTextChange(e, "BoxCompanyId")}
									>
										<MenuItem size="small" value={0}>
											<InputLabel> Customer</InputLabel>
										</MenuItem>

										{Companies.map((companyItem) => {
											return (
												<MenuItem
													size="small"
													key={companyItem.Guid}
													value={companyItem.Guid}
												>
													{companyItem.CompanyName} ({companyItem.AccCode})
												</MenuItem>
											);
										})}
									</Select>
								</Grid>

								{/**----------------------- */}

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										sx={{
											// ml: 60,
											// mt: 3,
											color: "white",
										}}
										variant="contained"
										size="small"
										startIcon={<Save />}
										onClick={handlePostUpdate}
									>
										Update
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Collapse>

					{/* --------------Form Ends Here-------------------- */}

					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
						}}
					>
						<Typography
							sx={{ flex: "1 1 100%" }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							Lines
						</Typography>

						<FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
							<OutlinedInput
								id="quickSearch_txt"
								variant="outlined"
								value={quickSearchValue}
								size="small"
								onChange={handleQuickSearchChange}
								endAdornment={
									<InputAdornment position="end">
										<Search />
									</InputAdornment>
								}
							/>
						</FormControl>

						<Tooltip title="Advanced Search">
							<IconButton
								onClick={() => setAdvanceSearchOpen(!advanceSearchOpen)}
							>
								{advanceSearchOpen ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</Tooltip>
					</Toolbar>

					<Collapse in={advanceSearchOpen} sx={{ marginBottom: 3 }}>
						<Box p={3}>
							<Typography variant="h6">Advanced Search</Typography>
						</Box>
					</Collapse>

					<TableContainer sx={{ padding: 2 }}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => {
										return (
											<TableCell
												key={headCell.id}
												align={headCell.numeric ? "right" : "left"}
												sortDirection={orderBy === headCell.id ? order : false}
											>
												<TableSortLabel
													active={orderBy === headCell.id}
													direction={orderBy === headCell.id ? order : "asc"}
													onClick={createSortHandler(headCell.id)}
												>
													{headCell.label}

													{orderBy === headCell.id ? (
														<Box component="span" sx={visuallyHidden}>
															{order === "desc"
																? "sorted descending"
																: "sorted ascending"}
														</Box>
													) : null}
												</TableSortLabel>
											</TableCell>
										);
									})}
									<TableCell></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{showLoadingSpinner && (
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ padding: 3 }}>
											<CircularProgress color="secondary" />
										</TableCell>
									</TableRow>
								)}

								{!showLoadingSpinner &&
									filteredLinesData
										.slice()
										.sort(getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<React.Fragment>
													<StyledTableRow
														hover
														tabIndex={-1}
														key={row.Guid}
														onClick={(e) => handleRowClick(row)}
														sx={{ cursor: "pointer" }}
													>
														{headCells.map((headCell) => {
															return (
																<TableCell key={row.Guid + headCell.id}>
																	<HighlightText
																		value={row[headCell.id] || ""}
																	/>
																</TableCell>
															);
														})}
														<TableCell>
															{rowDetailsExpanded !== row.BoxGuid ? (
																<IconButton
																	onClick={(e) =>
																		setRowDetailsExpanded(row.BoxGuid)
																	}
																>
																	<ExpandMore />
																</IconButton>
															) : (
																<IconButton
																	onClick={(e) => setRowDetailsExpanded("N/A")}
																>
																	<ExpandLess />
																</IconButton>
															)}
														</TableCell>
													</StyledTableRow>

													<Collapse
														key={row.BoxGuid + "Collapse"}
														in={rowDetailsExpanded === row.BoxGuid}
														sx={{ marginBottom: 3 }}
													>
														{/* <Box p={3}>
                                                            <Typography variant='body2'>Files</Typography>
                                                            <List>
                                                                {
                                                                    row.LibraryList.map(libr => {
                                                                        let fullPath = 'https://dmstonline.com/CRMSelfServiceDemo/Library/';
                                                                        fullPath += libr.LibrFilePath.replace(/\\/g, '/');
                                                                        console.log(fullPath);
                                                                        return(
                                                                            <MenuItem key={libr.LibrLibraryId}>
                                                                                <a
                                                                                    href={fullPath + libr.LibrFileName}
                                                                                >
                                                                                    {libr.LibrFileName}
                                                                                </a>
                                                                                : {new Date(libr.LibrCreatedDate).toISOString().split('T')[0]}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </List>

                                                        </Box> */}
													</Collapse>
												</React.Fragment>
											);
										})}
								{!showLoadingSpinner && emptyRows > 0 && (
									<StyledTableRow
										style={{
											height: 33 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component="div"
						count={linesData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Box>
			</Paper>
		</Container>
	);
};

export default BoxDetails;
