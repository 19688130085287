import React, { createContext, useContext, useState} from "react";


//tester function
// const sleep = timeout => {
//     return new Promise(resolve => {
//         setTimeout(resolve, timeout);
//     })
// }

const AppSettingsContext = createContext();



const lightTheme = {
                        palette: {
                                    mode:"light",
                                    primary: {
                                                main:'#161e2b'
                                    },
                                    secondary: {
                                                main:'#a4abd7'
                                    },
                                    background: {
                                                default: "#F0F0F0"
                                    },
                                    success:{
                                                main:'#4caf50',
                                    },
                                    error:{
                                                main:'#f44336',
                                                dark:'#ba000d'
                                    },
                                    warning:{
                                                main:'#ff9800',
                                    }
                        }
                    };

const darkTheme = {
                    palette: {
                        background:{
                                      default:'#212121',
                                      paper:'#424242',
                        },
                        mode:"dark",
                        primary: {
                                    main:'#161e2b',
                        },
                        secondary: {
                                    main:'#303968'
                        },
                        success:{
                                    main:'#4caf50',
                        },
                        error:{
                                    main:'#f44336',
                                    dark:'#ba000d'
                        },
                        warning:{
                                    main:'#ff9800',
                        }
                    }
    };

const AppSettingsProvider = (props) => {

    const {children} = {...props};


    const [currentTheme, setThemeMode] = useState(localStorage.getItem('savedTheme')==='dark'?darkTheme:lightTheme);

    const changeThemeMode = (themeMode) => {

        if(themeMode === 'dark')
        {
            setThemeMode(darkTheme);
        }
        else
        {
            setThemeMode(lightTheme);
        }

        localStorage.setItem('savedTheme', themeMode);

        return currentTheme;

    }

    const toggleThemeMode = () => {
        if(currentTheme.palette.mode === 'dark')
        {
            changeThemeMode('light');
        }
        else
        {
            changeThemeMode('dark');
        }
    }




    const apiSettingsContextValue = {
        currentTheme,
        lightTheme,
        darkTheme,
        changeThemeMode,
        toggleThemeMode,
    }



    return(
     
        <AppSettingsContext.Provider value={apiSettingsContextValue} {...props}>
                {children}
        </AppSettingsContext.Provider>
    )


}




const useAppSettings = () => useContext(AppSettingsContext);


export {AppSettingsProvider, useAppSettings};