import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {DPApiProvider} from './Context/DPApi';
import {AppSettingsProvider} from './Context/AppSettings';


ReactDOM.render(
  <React.StrictMode>
      <DPApiProvider>
        <AppSettingsProvider>
          <App />
        </AppSettingsProvider>
      </DPApiProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
