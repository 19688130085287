import React, { useState, useEffect, useMemo } from "react";
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import MaterialTable from "material-table";
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Fade,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	Modal,
	OutlinedInput,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	TextField,
	Toolbar,
	Typography,
} from "@mui/material";
// import jsPDF from 'jspdf';
import "jspdf-autotable";
import {
	CancelOutlined,
	Delete,
	Edit,
	KeyboardArrowDown,
	KeyboardArrowUp,
	Save,
	CheckCircle,
	SaveAltOutlined,
	Api,
	Search,
	AddCircleOutline,
	Close,
	ToggleOn,
	ToggleOff,
} from "@mui/icons-material";
import { Stack, style } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Chip } from "@mui/material";
import BoxDetails from "../Comps/BoxDetails";
import { useDPApi } from "../Context/DPApi";
import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";
const downloadPdf = () => {};
// -------------------------------Companies Functions-------------------------

// -------------------------------Companies Functions-------------------------
function CompanyPages() {
	const [rowCompanyData, setRowCompanyData] = useState([]);
	const [filteredCompanyData, setFilteredCompanyData] = useState([]);
	const [quickSearchValue, setQuickSearchValue] = useState("");
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const [filteredSummaryData, setFilteredSummaryData] = useState([]);

	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const [selectedTableRow, setSelectedTableRow] = useState({});
	//----------------  Data from DPAPI  -----------------------------------

	const { CompanyInfo } = useDPApi();
	const { CaseDetails } = useDPApi();

	// const navigate = useNavigate();

	//-----------------  Invoke Get all data  ------------------------------

	const {
		data: companiesData,
		isSuccess: companiesLoaded,
		refetch: refetchCompanies,
	} = CompanyInfo.useGetCompanies();
	const { data: CasesData } = CaseDetails.useGetCases();

	//-------------------  Pass data to states  ----------------------------

	const [getCompanies, setCompanies] = useState([]);
	const [casesData, setCasesData] = useState([]);

	//-------------------  sanitize data  ----------------------------

	const sanitizeCompaniesData = () => {
		let tempData = companiesData;
		setCompanies([...tempData]);
	};

	//-------------------  Invoke API data on render   ----------------------------

	useEffect(() => {
		if (companiesData) {
			sanitizeCompaniesData();
		}
	}, [companiesData, companiesLoaded]);

	//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
	//-----------------------------------------------------------------------------------------------
	//-- Destructered constant data  --
	//------------------------------------------------------------------------------------------------

	const [currentCompanyObject, setCurrentCompanyObject] = useState({});
	useMemo(() => {
		let NewCompanyObject = CompanyInfo.CompanyTemplate;

		//NewCompanyObject.ID = "";
		//NewCompanyObject.Guid = "";
		NewCompanyObject.CompanyName = "";
		NewCompanyObject.CompanyRegNo = "";
		NewCompanyObject.PostAddress = "";
		NewCompanyObject.PhysicalAddress = "";
		// NewCompanyObject.MainContact = "";
		NewCompanyObject.TelephoneNumber = "";
		NewCompanyObject.EmailAddress = "";
		NewCompanyObject.Active = "";
		NewCompanyObject.CreatedBy = "";
		NewCompanyObject.CreatedDate = "";
		NewCompanyObject.IsBranch = "";
		NewCompanyObject.AccCode = "";
		setCurrentCompanyObject({ ...NewCompanyObject });

		// console.log(NewCompanyObject);
	}, []);

	const headCells = [
		{
			id: "AccCode",
			numeric: false,
			disablePadding: true,
			label: "Acc Code",
		},
		{
			id: "CompanyName",
			numeric: false,
			disablePadding: true,
			label: "Company Name",
		},
		{
			id: "PhysicalAddress",
			numeric: false,
			disablePadding: true,
			label: "Address",
		},
		{
			id: "TelephoneNumber",
			numeric: false,
			disablePadding: true,
			label: "Contact",
		},
		{
			id: "EmailAddress",
			numeric: false,
			disablePadding: true,
			label: "Email",
		},
		{
			id: "CreatedDate",
			numeric: false,
			disablePadding: true,
			label: "Created Date",
		},
		{
			id: "Active",
			numeric: false,
			disablePadding: true,
			label: "Status",
		},
	];

	//------------------------------------------------------------------------------------------------
	// Handle Box onText Change
	//------------------------------------------------------------------------------------------------

	const onTextChange = (e, key, type = "text") => {
		console.log(e, key);
		if (type === "text") {
			let tempObject = { ...currentCompanyObject };
			tempObject[key] = e.target.value;
			setCurrentCompanyObject({ ...tempObject });
		}

		if (type === "select") {
			let tempObject = { ...currentCompanyObject };
			tempObject[key] = e.target.value;
			setCurrentCompanyObject({ ...tempObject });
		}

		if (type === "switch") {
			let tempObject = { ...currentCompanyObject };
			tempObject[key] = e.target.isChecked ? 1 : 0;
			setCurrentCompanyObject({ ...tempObject });
		}
		if (type === "autocomplete") {
			let tempObject = { ...currentCompanyObject };
			tempObject[key] = e.target.value;
			setCurrentCompanyObject({ ...tempObject });
		}
	};

	//-------------------------------------------------------------
	//        Handle Post Company Data
	//---------------------------------------------------------------
	const handlePostCompany = (e) => {
		e.preventDefault();
		// console.log(currentCompanyObject);
		let postingObject = { ...currentCompanyObject };
		CompanyInfo.AddCompany(postingObject);
		setOpen(false);
	};

	//-------------------------------------------------------------
	//        Handle Edit Company Data
	//---------------------------------------------------------------
	const handleUpdateCompany = (e) => {
		e.preventDefault();
		// console.log(currentCompanyObject);
		let postingObject = { ...currentCompanyObject };
		CompanyInfo.UpdateCompany(postingObject);
		refetchCompanies();
		setEditOpen(false);
	};

	//-------------------------------------------------------------
	//        Prefil Company Data on Edit
	//---------------------------------------------------------------
	const [editingCompany, setEditingCompany] = useState(false);

	const handleEditing = () => {
		setEditingCompany(!editingCompany);
		setEditOpen(true);
		setShowSelectedDialog(false);
	};
	useEffect(() => {
		if (editingCompany) {
			setCurrentCompanyObject({ ...selectedTableRow });
		}
	}, [editingCompany]);

	const updateCurrentObject = (newCompanyObject) => {
		setSelectedTableRow({ ...newCompanyObject });
	};

	useEffect(() => {
		if (typeof updateCurrentObject == "function") {
			updateCurrentObject(currentCompanyObject);
		}
	}, [currentCompanyObject]);

	//-------------------------------------------------------------
	//        Handle Delete Company Data
	//---------------------------------------------------------------
	const handleDeleteCompany = (e) => {
		e.preventDefault();
		let postingObject = { ...currentCompanyObject };
		console.log(postingObject);
		CompanyInfo.DeleteCompany(postingObject.Guid);
		setOpen(false);
		setShowSelectedDialog(false);
	};

	// ------------------------------------------------------------------------------------------------
	//  Load and Sanitize company Data
	// ------------------------------------------------------------------------------------------------
	const sanitizeCompanyData = () => {
		let tempData = [];
		// console.log(companiesData);

		companiesData.map((companyRow) => {
			let tempCompany = {};
			tempCompany.Guid = companyRow.Guid;

			headCells.map((header) => {
				tempCompany[header.id] = companyRow[header.id];
				return header;
			});

			//normalize dates
			if (tempCompany.CreatedDate) {
				tempCompany.CreatedDate = new Date(companyRow.CreatedDate)
					.toISOString()
					.split("T")[0];
			}

			tempData.push(tempCompany);
			return companyRow;
		});

		// setFilteredSummaryData([...tempData]);
		setFilteredCompanyData([...tempData]);
		setCompanies([...tempData]);
		setShowLoadingSpinner(false);
	};
	useMemo(() => {
		if (companiesData) {
			sanitizeCompanyData();
		}
	}, [companiesData]);

	const [showSelectedDialog, setShowSelectedDialog] = useState(false);

	const [editOpen, setEditOpen] = useState(false);
	const handleEditOpen = () => setEditOpen(true);

	const handleEditClose = () => setEditOpen(false);

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	//controlled for warning
	if (filteredSummaryData) {
	}

	//------------------------------------------------------------------------------------------------
	// Handle table selection
	//------------------------------------------------------------------------------------------------

	const handleRowClick = (item) => {
		let selectedCompany = companiesData.filter(
			(companyRow) => companyRow.Guid === item.Guid
		)[0];
		setSelectedTableRow(selectedCompany);
		setShowSelectedDialog(true);
	};

	// ------------------------------------------------------------------------------------------------
	//  Search and Filter
	// ------------------------------------------------------------------------------------------------
	const handleQuickSearchChange = (event) => {
		setQuickSearchValue(event.target.value);

		//filter values here
		let tempDataArray = getCompanies.filter((companyRow) => {
			let canReturn = false;
			Object.keys(companyRow).map((key) => {
				if (
					key !== "Guid" &&
					String(companyRow[key])
						.toLowerCase()
						.includes(event.target.value.toLowerCase())
				) {
					canReturn = true;
				}
				return companyRow;
			});
			return canReturn;
		});

		setFilteredCompanyData([...tempDataArray]);
		setFilteredSummaryData([...tempDataArray]);
	};

	const HighlightText = (props) => {
		const { value } = props;

		if (quickSearchValue) {
			if (
				String(value).toLowerCase().includes(quickSearchValue.toLowerCase())
			) {
				//construct the array
				let myTextArray = String(value)
					.toLowerCase()
					.split(quickSearchValue.toLowerCase());

				return (
					<div>
						{String(value).slice(0, myTextArray[0].length)}
						<Box sx={{ background: "#f07d06", display: "inline-flex" }}>
							{String(value).slice(
								myTextArray[0].length,
								myTextArray[0].length + quickSearchValue.length
							)}
						</Box>
						{String(value).slice(
							myTextArray[0].length + quickSearchValue.length
						)}
					</div>
				);
			}
		}

		return value;
	};

	// ------------------------------------------------------------------------------------------------
	//  Pagination
	// ------------------------------------------------------------------------------------------------
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// ------------------------------------------------------------------------------------------------
	//  Order and Sorting
	// ------------------------------------------------------------------------------------------------
	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - getCompanies.length) : 0;

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0,
		},
	}));

	//------------------------------------------------------------------------------------------------
	// Handle new form popup
	//------------------------------------------------------------------------------------------------

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 800,
		bgcolor: "background.paper",
		borderRadius: "8px",
		boxShadow: 24,
		p: 4,
	};

	return (
		<Box>
			{/* ------------------ Companies Form-------------------------------- */}
			<Paper>
				<Box>
					<Toolbar
						sx={{
							pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
						}}
					>
						<Typography
							sx={{ flex: "1 1 100%" }}
							variant="h6"
							id="tableTitle"
							component="div"
						>
							Companies
						</Typography>
						<FormControl sx={{ m: 1, width: "50ch" }} variant="outlined">
							{/* new case button start */}
							<Grid container spacing={2} mb={2} justifyContent="flex-end">
								<Grid item>
									<Button
										sx={{ m: 4 }}
										size="small"
										variant="contained"
										startIcon={<AddCircleOutline />}
										onClick={handleOpen}
									>
										New Comapny
									</Button>
								</Grid>
							</Grid>
							{/* new case button end */}

							<OutlinedInput
								id="quickSearch_txt"
								variant="outlined"
								value={quickSearchValue}
								size="small"
								onChange={handleQuickSearchChange}
								endAdornment={
									<InputAdornment position="end">
										<Search />
									</InputAdornment>
								}
							/>
						</FormControl>
					</Toolbar>

					<TableContainer sx={{ padding: 2 }}>
						<Table size="small">
							<TableHead>
								<TableRow>
									{headCells.map((headCell) => (
										<TableCell
											key={headCell.id + "_Header"}
											align={headCell.numeric ? "right" : "left"}
											sortDirection={orderBy === headCell.id ? order : false}
										>
											<TableSortLabel
												active={orderBy === headCell.id}
												direction={orderBy === headCell.id ? order : "asc"}
												onClick={createSortHandler(headCell.id)}
											>
												{headCell.label}

												{orderBy === headCell.id ? (
													<Box component="span" sx={visuallyHidden}>
														{order === "desc"
															? "sorted descending"
															: "sorted ascending"}
													</Box>
												) : null}
											</TableSortLabel>
										</TableCell>
									))}
								</TableRow>
							</TableHead>

							<TableBody>
								{showLoadingSpinner && (
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ padding: 3 }}>
											<CircularProgress color="secondary" />
										</TableCell>
									</TableRow>
								)}

								{!showLoadingSpinner &&
									filteredCompanyData
										.slice()
										.sort(getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<StyledTableRow
													key={row.Guid}
													hover
													tabIndex={-1}
													onClick={(e) => handleRowClick(row)}
													sx={{ cursor: "pointer" }}
												>
													{headCells.map((headCell) => {
														if (headCell.id === "Active") {
															return (
																<TableCell key={row.Guid + headCell.id}>
																	{row[headCell.id] === 1 ? (
																		<Chip
																			color="success"
																			label="Active"
																			icon={<ToggleOn />}
																		/>
																	) : (
																		<Chip
																			color="error"
																			label="Inactive"
																			icon={<ToggleOff />}
																		/>
																	)}
																</TableCell>
															);
														}
														return (
															<TableCell key={row.Guid + headCell.id}>
																<HighlightText value={row[headCell.id] || ""} />
															</TableCell>
														);
													})}
												</StyledTableRow>
											);
										})}
								{!showLoadingSpinner && emptyRows > 0 && (
									<StyledTableRow
										style={{
											height: 33 * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</StyledTableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100]}
						component="div"
						count={getCompanies.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Box>
			</Paper>

			{/* ------------------ Companies Dialog Box-------------------------------- */}

			<Dialog
				open={showSelectedDialog}
				onClose={(e) => setShowSelectedDialog(false)}
				maxWidth={"lg"}
			>
				<DialogTitle>
					{selectedTableRow.AccCode} - {selectedTableRow.CompanyName}
					{/* Details */}
				</DialogTitle>
				<DialogContent>
					<Paper>
						<Table
							sx={{ minWidth: 1050 }}
							size="small"
							aria-label="delivery note"
						>
							<TableHead
								sx={{
									display: "table-header-group",
									backgroundColor: "#dddddd;",
								}}
							>
								<TableRow sx={{ m: 1 }} align="center">
									<TableCell align="center">
										<strong>Company Reg No</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Post Address</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Telefone Number</strong>
									</TableCell>

									<TableCell align="center">
										<strong> Email Address</strong>
									</TableCell>

									<TableCell align="center">
										<strong> Created By</strong>
									</TableCell>

									<TableCell align="center">
										<strong> IsBranch</strong>
									</TableCell>

									<TableCell align="center">
										<strong>Status</strong>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								<TableRow
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.CompanyRegNo || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.PostAddress || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.TelephoneNumber || "N/A"}
											{/* {currentCompanyObject.MainContact || "N/A"} */}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.EmailAddress || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.CreatedBy || "N/A"}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.IsBranch === 1 ? (
												<Chip icon={<ToggleOn />} label="Yes" color="success" />
											) : (
												<Chip icon={<ToggleOff />} label="No" color="error" />
											)}
										</Typography>
									</TableCell>
									<TableCell align="center">
										<Typography variant="body2">
											{selectedTableRow.Active === 1 ? (
												<Chip
													icon={<ToggleOn />}
													label="Active"
													color="success"
												/>
											) : (
												<Chip
													icon={<ToggleOff />}
													label="Inactive"
													color="error"
												/>
											)}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</Paper>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						size="small"
						startIcon={<Edit />}
						onClick={(e) => handleEditing(true)}
					>
						Edit
					</Button>
					<Button
						variant="contained"
						size="small"
						color="error"
						startIcon={<Delete />}
						onClick={handleDeleteCompany}
					>
						Delete
					</Button>
					<Button
						variant="contained"
						size="small"
						startIcon={<Close />}
						onClick={(e) => setShowSelectedDialog()}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			{/* ---------------------------DIALOG INPUT FORM----------------------------------- */}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Divider>Company Detail Form</Divider>
						<Box>
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										required
										// id="outlined-required"
										label="Acc Code"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.AccCode || ""}
										onChange={(e) => onTextChange(e, "AccCode")}
									/>
									<TextField
										// Enable
										// id="outlined-disabled"
										label="Company Name"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CompanyName || ""}
										onChange={(e) => onTextChange(e, "CompanyName")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-password-input"
										label="Physical Address"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.PhysicalAddress || ""}
										onChange={(e) => onTextChange(e, "PhysicalAddress")}
									/>

									<TextField
										// id="outlined-read-only-input"
										label="Contact Number"
										// type="number"
										size="small"
										type="text"
										sx={{ width: "50%" }}
										value={currentCompanyObject.TelephoneNumber || ""}
										onChange={(e) => onTextChange(e, "TelephoneNumber")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-search"
										label="Email Address"
										type="Email"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.EmailAddress || ""}
										onChange={(e) => onTextChange(e, "EmailAddress")}
									/>
									<TextField
										// id="outlined-search"
										label="Created Date"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.CreatedDate || ""}
										onChange={(e) => onTextChange(e, "CreatedDate")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Status"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.Active || ""}
										onChange={(e) => onTextChange(e, "Active")}
									/>

									<TextField
										// id="outlined-number"
										label="Is Branch"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.IsBranch || ""}
										onChange={(e) => onTextChange(e, "IsBranch")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-helperText"
										label="Post Address"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.PostAddress || ""}
										onChange={(e) => onTextChange(e, "PostAddress")}
									/>

									<TextField
										// id="outlined-required"
										label="Company Reg No"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CompanyRegNo || ""}
										onChange={(e) => onTextChange(e, "CompanyRegNo")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// Enable
										// id="outlined-disabled"
										label=" Created By"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CreatedBy || ""}
										onChange={(e) => onTextChange(e, "CreatedBy")}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										variant="contained"
										size="small"
										startIcon={<Save />}
										onClick={handlePostCompany}
									>
										Save
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>

			{/* ----------------------Modal Open On Edit------------------------- */}

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={editOpen}
				onClose={handleEditClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={editOpen}>
					<Box sx={style}>
						<Divider>Company Detail Form</Divider>
						<Box>
							<Paper
								elevation={4}
								sx={{
									p: 3,
								}}
							>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										required
										// id="outlined-required"
										label="Acc Code"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.AccCode || ""}
										onChange={(e) => onTextChange(e, "AccCode")}
									/>
									<TextField
										// Enable
										// id="outlined-disabled"
										label="Company Name"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CompanyName || ""}
										onChange={(e) => onTextChange(e, "CompanyName")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-password-input"
										label="Physical Address"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.PhysicalAddress || ""}
										onChange={(e) => onTextChange(e, "PhysicalAddress")}
									/>

									<TextField
										// id="outlined-read-only-input"
										label="Contact Number"
										// type="number"
										size="small"
										type="text"
										sx={{ width: "50%" }}
										value={currentCompanyObject.TelephoneNumber || ""}
										onChange={(e) => onTextChange(e, "TelephoneNumber")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-search"
										label="Email Address"
										type="Email"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.EmailAddress || ""}
										onChange={(e) => onTextChange(e, "EmailAddress")}
									/>
									<TextField
										// id="outlined-search"
										label="Created Date"
										size="small"
										type="date"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.CreatedDate || ""}
										onChange={(e) => onTextChange(e, "CreatedDate")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-number"
										label="Status"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.Active || ""}
										onChange={(e) => onTextChange(e, "Active")}
									/>

									<TextField
										// id="outlined-number"
										label="Is Branch"
										type="number"
										size="small"
										sx={{ width: "50%" }}
										InputLabelProps={{
											shrink: true,
										}}
										value={currentCompanyObject.IsBranch || ""}
										onChange={(e) => onTextChange(e, "IsBranch")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// id="outlined-helperText"
										label="Post Address"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.PostAddress || ""}
										onChange={(e) => onTextChange(e, "PostAddress")}
									/>

									<TextField
										// id="outlined-required"
										label="Company Reg No"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CompanyRegNo || ""}
										onChange={(e) => onTextChange(e, "CompanyRegNo")}
									/>
								</Grid>
								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"space-between"}
									gap={3}
								>
									<TextField
										// Enable
										// id="outlined-disabled"
										label=" Created By"
										size="small"
										sx={{ width: "50%" }}
										value={currentCompanyObject.CreatedBy || ""}
										onChange={(e) => onTextChange(e, "CreatedBy")}
									/>
								</Grid>

								<Grid
									item
									xs={12}
									sm={6}
									md={3}
									mt={5}
									display={"flex"}
									justifyContent={"center"}
									gap={3}
								>
									<Button
										variant="contained"
										size="small"
										startIcon={<Save />}
										onClick={handleUpdateCompany}
									>
										Update
									</Button>
								</Grid>
							</Paper>
						</Box>
					</Box>
				</Fade>
			</Modal>
		</Box>
	);
}
export default CompanyPages;
