import React, { createContext, useContext, useState } from "react";
import https from "https";
import axios from "axios";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import DPNotificationsEvents from "../Context/DPNotifications";

const DPApiContext = createContext({});
const DPAuthContext = createContext({});

const agent = new https.Agent({
	rejectUnauthorized: false,
});
const api = axios.create({
	baseURL: "https://160.242.115.187:44313/api/",
	httpsAgent: agent,
});
//const api = axios.create({baseURL: 'https://localhost:44313/api/', httpsAgent: agent});
// api.intercaptors.request(
//     (config) => {
//         // Do something before request is sent
//     },
//     (error) => {
//         // Do something with request error
//     }
// )

api.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger

		return response;
	},
	(error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		if (error.response) {
			if (error.response.status === 401) {
				DPNotificationsEvents.dispatch("Request is not authorized", "error");
			}

			if (error.response.status === 408) {
				DPNotificationsEvents.dispatch("Server not responding", "error");
			}

			if (error.response.status === 500) {
				DPNotificationsEvents.dispatch("Internal server error", "error");
			}
		}

		return Promise.reject(error);
	}
);

const queryCLient = new QueryClient();

const AuthOps = () => {
	const [isAuth, setIsAuth] = useState(false);
	const [authUser, setAuthUser] = useState(null);
	const [jwt, setJWT] = useState(false);
	const [resettingPassword, setResettingPassword] = useState(false);

	const loginWithUsernamePassword = (username, password) => {
		//login user here
		if (username && password) {
			let body = {
				Username: username,
				Password: password,
			};

			api
				.post(`UserAccounts/login/`, body, {
					header: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.data) {
						let receivedJWT = res.data.Access_Token || "";
						if (receivedJWT === "") {
							return;
						}
						checkAuth(receivedJWT, true);
					}
				});
		}
	};

	const forceResetPassword = () => {
		logout();
		setResettingPassword(true);
	};

	const doneResetPassword = () => {
		logout();
		setResettingPassword(false);
	};

	const saveAuth = async (jwt) => {
		setIsAuth(true);

		setJWT(jwt);
		localStorage.setItem("jwt", jwt);
		api.defaults.headers.common.Authorization = `Bearer ${jwt}`;

		await api
			.get(`/UserAccounts/`)
			.then((res) => {
				//mod user for security
				let newUser = res.data;
				//this is for reading claims directly from the jwt for validation if needed
				//let jwtJSON = JSON.parse(atob(jwt.split('.')[1]));

				//Created DP User Object here to share
				newUser.User.FullName =
					newUser.User.FirstName + " " + newUser.User.LastName;

				setAuthUser(newUser);
			})
			.catch((error) => {
				console.log(
					`EXCEPTION :: Getting User Account information :: => `,
					error
				);
				setIsAuth(false);
				setAuthUser(null);
				setJWT(null);
				logout();
				// localStorage.removeItem('jwt');
				// api.defaults.headers.common.Authorization = ``;
			});
	};

	const checkAuth = (jwt, newLogin = false) => {
		if (newLogin) {
			saveAuth(jwt);
		} else {
			if (localStorage.getItem("jwt")) {
				//verify user
				jwt = localStorage.getItem("jwt");
				saveAuth(jwt);
				return true;
			} else {
				return false;
			}
		}
	};

	const logout = () => {
		console.log("Logout");
		setIsAuth(false);
		setAuthUser(null);
		setJWT(null);
		localStorage.removeItem("jwt");
		//api.defaults.headers.common.Authorization = ``;
	};

	return {
		loginWithUsernamePassword,
		logout,
		isAuth,
		authUser,
		jwt,
		checkAuth,
		forceResetPassword,
		doneResetPassword,
		resettingPassword,
	};
};

const DPAuthProvider = (props) => {
	const { children } = { ...props };

	const Auth = AuthOps();

	const authContextValue = {
		Auth,
		isAuth: Auth.isAuth,
		jwt: Auth.jwt,
	};

	return (
		<DPAuthContext.Provider value={authContextValue} {...props}>
			{children}
		</DPAuthContext.Provider>
	);
};

const useDPAuth = () => useContext(DPAuthContext);

// ----------------------------------------------------------------------------------------------------------------------
//   Helper Functions to convert nested JSON to objects
// ----------------------------------------------------------------------------------------------------------------------
const parseNestedObject = (data) => {
	try {
		let newData = {};
		if (typeof data === "object" && !Array.isArray(data) && data !== null) {
			Object.keys(data).map((key) => {
				try {
					newData[key] = JSON.parse(data[key]);
				} catch {
					newData[key] = data[key];
				}
				return key;
			});

			return newData;
		}
	} catch {
		return data;
	}
};

const parseDeepData = (data) => {
	let newData = {};
	if (Array.isArray(data)) {
		//array is being passed
		newData = [];
		data.map((dataObject) => {
			let newObject = parseNestedObject(dataObject);
			newData.push(newObject);
			return dataObject;
		});
	} else {
		newData = parseNestedObject(data);
	}
	return newData;
};

// ----------------------------------------------------------------------------------------------------------------------
//   End of Helper Functions to convert nested JSON to objects
// ----------------------------------------------------------------------------------------------------------------------

const ProfileOps = () => {
	const FetchProfile = async () => {
		return await api.get(`/UserAccount/`).then((res) => {
			console.log(res.data[0]);
			return res.data[0];
		});
	};

	const useGetProfileData = () =>
		useQuery("GetProfile", FetchProfile, { refetchOnWindowFocus: false });

	return { useGetProfileData };
};

const NotificationsOps = () => {
	const NotificationTemplate = {
		Id: 1,
		Guid: "GUID GOES HERE",
		NotificationType: 1,
		UserId: "DESTINATION USER GUID HERE",
		CreatedAt: "2022-02-16T15:25:54.5017476+02:00",
		CreatedBy: "SENDING USER GUID HERE",
		Seen: 0,
		Details: "Notification text goes here",
	};

	const FetchNotifications = async () => {
		return await api.get(`/notifications/`).then((res) => res.data);
	};

	const FetchNotification = async (guid) => {
		return await api.get(`/notifications?guid=${guid}`).then((res) => res.data);
	};

	const AddNotification = async (notificationObject) => {
		let body = notificationObject;
		return await api
			.post("/notifications/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const DeleteNotification = async (object) => {
		if (Array.isArray(object)) {
			//received array of elements

			let paramString = "";
			object.map((item, index) => {
				if (index !== 0) {
					paramString += `&guids=`;
				}

				paramString += `${item.Guid}`;
				return item;
			});

			return await api
				.delete(`/notifications?guids=${paramString}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		} else {
			//singular element received
			return await api
				.delete(`/notifications?guids=${object.Guid}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		}
	};

	const useGetNotifications = () =>
		useQuery("AllNotifications", FetchNotifications, {
			refetchOnWindowFocus: false,
		});
	const useGetNotification = (guid) =>
		useQuery("NotificationGet", () => FetchNotification(guid), {
			refetchOnWindowFocus: false,
		});

	return {
		useGetNotifications,
		useGetNotification,
		NotificationTemplate,
		AddNotification,
		DeleteNotification,
	};
};

// ----------------------------------------------------------------------------------------------------------------------
//   CASE TYPES CRUD OPERAIONS
// ----------------------------------------------------------------------------------------------------------------------
const CaseTypeOps = () => {
	const CaseTypeTemplate = {
		ID: 0,
		Guid: "GUID HERE",
		Name: "NAME OF CASA TYPE Here",
	};

	const FetchCaseType = async () => {
		return await api.get(`/CaseType/`).then((res) => parseDeepData(res.data));
	};

	const useGetCaseType = () =>
		useQuery("AllcaseTypes", FetchCaseType, { refetchOnWindowFocus: false });
	return { useGetCaseType, CaseTypeTemplate };
};

// ----------------------------------------------------------------------------------------------------------------------
//   TYPES CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const TypeOps = () => {
	const TypeTemplate = {
		ID: 0,
		Guid: "GUID HERE",
		TypeName: "NAME OF TYPE Here",
	};

	const FetchType = async () => {
		return await api.get(`/Type/`).then((res) => parseDeepData(res.data));
	};

	const useGetType = () =>
		useQuery("AllTypes", FetchType, { refetchOnWindowFocus: false });
	return { useGetType, TypeTemplate };
};

// ----------------------------------------------------------------------------------------------------------------------
//   CASE DESCRIPTION TYPE CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const CaseDescriptionTypeOps = () => {
	const CaseDescriptionTypeTemplate = {
		ID: 0,
		Guid: "GUID HERE",
		Name: "NAME OF CASE DESCRIPTION TYPE Here",
	};

	const FetchCaseDescriptionType = async () => {
		return await api
			.get(`/CaseDescriptionType/`)
			.then((res) => parseDeepData(res.data));
	};

	const useGetCaseDescriptionType = () =>
		useQuery("AllCaseDescriptionTypes", FetchCaseDescriptionType, {
			refetchOnWindowFocus: false,
		});
	return { useGetCaseDescriptionType, CaseDescriptionTypeTemplate };
};
//---------------------------------------------------------------------------------------------------
//   FULL CASE OBJECT CRUD OPS
// ---------------------------------------------------------------------------------------------------
const CasesOps = () => {
	const CasesTemplate = {
		Guid: "Case Guid Here",
		Company: "Comapny Here ",
		ReferenceNo: null,
		PurchaseOrderNo: null,
		CaseType: "CaseType Guid",
		Type: "TypeGuid",
		CaseDescriptionType: [],
		Qty: 0,
		Source: "Source Guid",
		BoxLinked: [],
		Status: "Status Guid",
		RequestDetail: null,
		CreatedBy: "Guid Here",
		OrderDate: "2022-01-25T10:36:46.9821074+02:00",
		CreatedDate: "2022-01-25T10:36:46.9821074+02:00",
		ClosedBy: "Users Guid ",
		ClosedDate: "2022-01-25T10:36:46.9821074+02:00",
		CaseName: "Case name here",
		CaseAssignedUserId: null,
	};

	const FetchCases = async () => {
		return await api
			.get(`/CaseDetails/`)
			.then((res) => parseDeepData(res.data));
	};

	const FetchCase = async (guid) => {
		return await api
			.get(`/CaseDetails?guid=${guid}`)
			.then((res) => parseDeepData(res.data));
	};
	const AddCase = async (caseObject) => {
		let body = { ...caseObject };

		//stringify all object properties
		delete body.Guid;
		delete body.CreatedBy;
		body.BoxLinked = JSON.stringify(body.BoxLinked);
		body.CaseDescriptionType = JSON.stringify(body.CaseDescriptionType);
		body.Data = JSON.stringify(body.Data);

		return await api
			.post("/CaseDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const UpdatCaseDetail = async (CaseDetailObject) => {
		let body = { ...CaseDetailObject };

		body.BoxLinked = JSON.stringify(body.BoxLinked);
		body.CaseDescriptionType = JSON.stringify(body.CaseDescriptionType);
		return await api
			.put("/CaseDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};
	const DeleteCaseDetail = async (object) => {
		if (Array.isArray(object)) {
			//received array of elements

			let paramString = "";
			object.map((item, index) => {
				if (index !== 0) {
					paramString += `&guid=`;
				}

				paramString += `${item.Guid}`;
				return item;
			});

			return await api
				.delete(`/CaseDetails?guid=${paramString}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		} else {
			//singular element received
			return await api
				.delete(`/CaseDetails?guid=${object.Guid}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		}
	};
	const useGetCases = () =>
		useQuery("AllCaseDetails", FetchCases, { refetchOnWindowFocus: false });
	const useGetCase = (guid) =>
		useQuery("CaseGet", () => FetchCase(guid), { refetchOnWindowFocus: false });
	return {
		useGetCases,
		useGetCase,
		CasesTemplate,
		AddCase,
		UpdatCaseDetail,
		DeleteCaseDetail,
	};
};

// ----------------------------------------------------------------------------------------------------------------------
// COMPANY CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const CompaniesOps = () => {
	const CompanyTemplate = {
		// ID: 0,
		//Guid: "",
		AccCode: "",
		CompanyName: "",
		PhysicalAddress: "",
		//MainContact: "", //This is GUID of user
		EmailAddress: "",
		CreatedDate: "",
		Active: 1,
		CompanyRegNo: "",
		PostAddress: "",
		TelephoneNumber: "",
		CreatedBy: "",
		IsBranch: 0,
		// Deleted: 0,
		// Parent: null,
		// UpdatedBy: "USER GUID HERE",
		// UpdatedDate: "2013-10-02T12:08:43",
		// LibraryDir: "LIBRARY DIRECTORY HERE",
		// FinanceLinkCode: null,
		// DatabaseName: null,
		// Data: '{}',
		// CrmId: 4,
		// CrmId2: null,
		// CrmId3: null,
		// CrmId4: null,
		// CrmId5: null,
	};

	const FetchCompanies = async () => {
		return await api.get(`/Companies/`).then((res) => parseDeepData(res.data));
	};
	const FetchCompany = async (guid) => {
		return await api
			.get(`/Companies?guid=${guid}`)
			.then((res) => parseDeepData(res.data));
	};

	const AddCompany = async (companyObject) => {
		let body = companyObject;

		//stringify all object properties
		body.DeleteData = JSON.stringify(body.DeleteData);
		body.CompanyData = JSON.stringify(body.CompanyData);

		return await api
			.post("/Companies/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};
	const UpdateCompany = async (companyObject) => {
		let newCompObject = { ...companyObject };
		newCompObject.Data = JSON.stringify(companyObject.Data);

		let body = { ...newCompObject };

		return await api
			.put(`/Companies/${companyObject.Guid}`, body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const DeleteCompany = async (guid) => {
		//singular element received
		return await api.delete(`/Companies/${guid}`).then((res) => {
			let response = JSON.parse(res.data);
			DPNotificationsEvents.dispatch(response.Message, response.ResponseType);
		});
	};

	const useGetCompanies = () =>
		useQuery("AllCompanies", FetchCompanies, { refetchOnWindowFocus: false });
	const useGetCompany = (guid) =>
		useQuery("CompanyGet", () => FetchCompany(guid), {
			refetchOnWindowFocus: false,
		});

	return {
		useGetCompanies,
		useGetCompany,
		CompanyTemplate,
		AddCompany,
		UpdateCompany,
		DeleteCompany,
	};
};

// ----------------------------------------------------------------------------------------------------------------------
// USERS CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const UsersOps = () => {
	const UsersTemplate = {
		// Id: 1,
		// Guid: "",
		CompanyId: "",
		FirstName: "System",
		LastName: "Administrator",
		Email: "",
		Tel: null,
		InternalUser: 1,

		Active: 1,
		Password: "Admin1234",
		GlobalAdmin: 0,
		ForceChangePassword: 1,
		UserName: "Admin",

		// Mobile: null,
		// CompanyCrmId: null,
		// CompanyAdmin: 0,
		// PasswordSalt: "Admin1234",
		// CreatedAt: "2022-06-17T02:39:19.58",
		// CreatedBy: null,
		// UserType: null,
		// Data: "{}",
		// CrmId: 1,
		// FirstLogon: null,
		// LastLogon: null,
		// Logons: null,
		// PassResetToken: null,
		// ConfirmPassword: null,
		// VerificationToken: null,
		// ResetTokenExpires: null,
	};

	const FetchUsers = async () => {
		return await api.get(`/Users/`).then((res) => parseDeepData(res.data));
	};
	const FetchUser = async (guid) => {
		return await api
			.get(`/Users?guid=${guid}`)
			.then((res) => parseDeepData(res.data));
	};

	const AddUser = async (UserObject) => {
		let body = UserObject;

		//stringify all object properties
		body.DeleteData = JSON.stringify(body.DeleteData);
		body.UserData = JSON.stringify(body.UserData);

		return await api
			.post("/Users/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};
	const UpdateUser = async (UserObject) => {
		let newUserObject = { ...UserObject };
		newUserObject.Data = JSON.stringify(UserObject.Data);

		let body = { ...newUserObject };

		return await api
			.put(`/Users/${UserObject.Guid}`, body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const DeleteUser = async (guid) => {
		//singular element received
		return await api.delete(`/Users/${guid}`).then((res) => {
			let response = JSON.parse(res.data);
			DPNotificationsEvents.dispatch(response.Message, response.ResponseType);
		});
	};

	const useGetUsers = () =>
		useQuery("AllUsers", FetchUsers, { refetchOnWindowFocus: false });
	const useGetUser = (guid) =>
		useQuery("UserGet", () => FetchUser(guid), {
			refetchOnWindowFocus: false,
		});

	return {
		useGetUsers,
		useGetUser,
		UsersTemplate,
		AddUser,
		UpdateUser,
		DeleteUser,
	};
};
// ----------------------------------------------------------------------------------------------------------------------
//   SOURCE OPS
// ----------------------------------------------------------------------------------------------------------------------
const SourceOps = () => {
	const SourceTemplate = {
		ID: 0,
		Guid: "GUID HERE",
		SourceName: "NAME OF Source Here",
	};

	const FetchSource = async () => {
		return await api.get(`/Sources/`).then((res) => parseDeepData(res.data));
	};

	const useGetSource = () =>
		useQuery("AllTSources", FetchSource, { refetchOnWindowFocus: false });
	return { useGetSource, SourceTemplate };
};
// ----------------------------------------------------------------------------------------------------------------------
//   STATUS OPS
// ----------------------------------------------------------------------------------------------------------------------
const StatusOps = () => {
	const StatusTemplate = {
		ID: 0,
		Guid: "GUID HERE",
		StatusName: "NAME OF Status Here",
	};

	const FetchStatus = async () => {
		return await api.get(`/Status/`).then((res) => parseDeepData(res.data));
	};

	const useGetStatus = () =>
		useQuery("AllStatus", FetchStatus, { refetchOnWindowFocus: false });
	return { useGetStatus, StatusTemplate };
};
// ----------------------------------------------------------------------------------------------------------------------
// BOXES CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const BoxDetailsOps = () => {
	const BoxTemplate = {
		BoxBoxNumber: "",
		// BoxDescription: "",
		BoxDateBoxReceived: "",
		BoxLocalBoxRef: "",
		BoxOrderNumber: "",
		BoxCompanyId: "",
		// BoxBoxesId: "",
		// "BoxGuid": "BOX GUI HERE",
		// "BoxCreatedBy": "2013-10-02T12:08:43",
		// "BoxCreatedDate": "2013-10-02T12:08:43",
		// "BoxUpdatedBy": "GUID HERE",
		// "BoxUpdatedDate": "2013-10-02T12:08:43",
		// "BoxTimeStamp": "2013-10-02T12:08:43",
		// "BoxDeleted": null,
		// "BoxSecterr": null,
		// "BoxName": "GUID HERE",
		// "BoxWorkflowId": null,
		// "BoxStatus": "BOX STATUS HERE",
		// "BoxUserId": "GUID ID HERE",
		// "BoxChannelId": "ID HERE",
		// "BoxBoxNumber": 1,
		// "BoxDateRangeFrom": "2013-10-02T12:08:43",
		// "BoxDateRangeTo": null,
		// "BoxDescription": null,
		// "BoxLineNumber": null,
		// "BoxLineSubAccount": null,
		// "BoxLineSubSubAccount": null,
		//BoxLineDescription: null,
		//BoxLineKeyword: null,
		// "BoxLineRangeFrom": null,
		// "BoxLineRangeTo": null,
		//BoxLineDateFrom: null,
		//BoxLineDateTo: null,
		// "BoxCheckedBy": null,
		// "BoxDateTimeChecked": null,
		// "BoxLineEnteredBy": null,
		// "BoxLineCheckedBy": null,
		// "BoxDateLineEntered": null,
		// "BoxDateLineChecked": null,
		// "BoxLineCheckingErrors": null,
		// "BoxCompanyid": null,
		// "BoxPersonid": null,
		// "BoxNumberEntries": null,
		// "BoxKeyword": null,
		// "BoxDateBoxReceived": null,
		// "BoxStage": null,
		// "BoxDeclineComments": null,
		// "BoxDateDestruction": null,
		// "BoxCaptureController": null,
		// "BoxDateTimetoCapturing": null,
		// "BoxDatetimedistrcapturing": null,
		// "BoxReferringCaseId": null,
		// "BoxUserCapturer": null,
		// "BoxUserChecker": null,
		// "BoxDateToChecking": null,
		// "BoxOrderNumber": null,
		// "BoxFinanceUser": null,
		// "BoxDateToFinance": null,
		// "BoxPercentageLinesAdd": null,
		// "BoxPrimaryCaseId": null,
		// "BoxRemovecase": null,
		// "BoxDummyline": null,
		// "BoxDestructiondate": null,
		// "BoxOrderDate": null,
		// "BoxLocalBoxRef": null,
		// "BoxRetentionSchedule": null,
		// "BoxLegalHoldsDescription": null,
		// "BoxSafe": null,
		// "BoxLocation": null,
		// "BoxBranch": null,
		// "BoxProcessingLocation": null,
		// "BoxCostCentre": null
	};

	const FetchBoxes = async () => {
		return await api
			.get(`/BoxesDetails/`)
			.then((res) => parseDeepData(res.data));
	};
	const FetchBox = async (guid) => {
		return await api
			.get(`/BoxesDetails/${guid}`)
			.then((res) => parseDeepData(res.data));
	};

	const AddBox = async (BoxObject) => {
		let body = BoxObject;

		//stringify all object properties
		body.DeleteData = JSON.stringify(body.DeleteData);
		body.BoxObject = JSON.stringify(body.BoxObject);
		body.Data = JSON.stringify(body.Data);

		return await api
			.post("/BoxesDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};
	const UpdateBox = async (BoxGuid, BoxObject) => {
		let body = {
			BoxGuid: BoxGuid,
			BoxObject: BoxObject,
		};
		return await api
			.put("/BoxesDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const DeleteBox = async (object) => {
		if (Array.isArray(object)) {
			//received array of elements

			let paramString = "";
			object.map((item, index) => {
				if (index !== 0) {
					paramString += `&guid=`;
				}

				paramString += `${item.Guid}`;
				return item;
			});

			return await api
				.delete(`/BoxesDetails?guid=${paramString}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		} else {
			//singular element received
			return await api
				.delete(`/BoxesDetails?guid=${object.Guid}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		}
	};

	const useGetBoxes = () =>
		useQuery("AllBoxes", FetchBoxes, { refetchOnWindowFocus: false });
	const useGetBox = (guid) =>
		useQuery("BoxGet", () => FetchBox(guid), { refetchOnWindowFocus: false });

	return { useGetBoxes, useGetBox, BoxTemplate, AddBox, UpdateBox, DeleteBox };
};

// ----------------------------------------------------------------------------------------------------------------------
// LINES CRUD OPS
// ----------------------------------------------------------------------------------------------------------------------
const LineDetailsOps = () => {
	const LinesTemplate = {
		// LinesLinesId: "",
		// Guid: "GUID HERE",
		LinesDescription: null,
		LinesKeyword: null,
		LinesDatefrom: null,
		LinesDateto: null,
		LinesCreatedDate: null,
		LinesLocalLinesReference: null,

		// LinesCreatedBy: null,
		// LinesCreatedDate: null,
		// LinesUpdatedBy: null,
		// LinesUpdatedDate: null,
		// LinesTimeStamp: null,
		// LinesDeleted: null,
		// LinesCompanyId: null,
		// LinesPersonId: null,
		// LinesUserId: null,
		// LinesWorkflowId: null,
		// LinesBoxesId: null,
		// LinesCheckedby: null,
		// LinesDatefrom: null,
		// LinesDateto: null,
		// LinesDescription: null,
		// LinesEnteredby: null,
		// LinesKeyword: null,
		// LinesNumber: null,
		// LinesRangefrom: null,
		// LinesRangeto: null,
		// LinesSubaccount: null,
		// LinesSubsubaccount: null,
		// LinesDateChecked: null,
		// LinesStatus: null,
		// LinesCheckDetail: null,
		// LinesCheckSpot: null,
		// LinesChecked: null,
		// LinesCheckPassedBy: null,
		// LinesDateCheckingPassed: null,
		// LinesRejectedReason: null,
		// LinesDateAssignedChecking: null,
		// LinesDateLinesCaptured: null,
		// LinesName: null,
		// LinesDateRejected: null,
		// LinesDestructiondate: null,
		// LinesDateordered: null,
		// LinesOrdernumber: null,
		// LinesRetentionSchedule: null,
		// LinesDocRetSchDescription: null,
		// LinesLegalHolds: null,
		// LinesLocalLinesReference: null,
		// LinesCreatedByPerson: null,
	};

	const FetchLines = async () => {
		return await api
			.get(`/LinesDetails/`)
			.then((res) => parseDeepData(res.data));
	};
	const FetchLine = async (guid) => {
		return await api
			.get(`/LinesDetails?guid=${guid}`)
			.then((res) => parseDeepData(res.data));
	};

	const AddLine = async (LineObject) => {
		let body = LineObject;

		//stringify all object properties
		body.DeleteData = JSON.stringify(body.DeleteData);
		body.LineObject = JSON.stringify(body.LineObject);
		body.Data = JSON.stringify(body.Data);

		return await api
			.post("/LinesDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};
	const UpdateLine = async (LineGuid, LineObject) => {
		let body = {
			LineGuid: LineGuid,
			LineObject: LineObject,
		};
		return await api
			.put("/LinesDetails/", body, {
				header: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res);
				return res.data;
			});
	};

	const DeleteLine = async (object) => {
		if (Array.isArray(object)) {
			//received array of elements

			let paramString = "";
			object.map((item, index) => {
				if (index !== 0) {
					paramString += `&guid=`;
				}

				paramString += `${item.Guid}`;
				return item;
			});

			return await api
				.delete(`/LinesDetails?guid=${paramString}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		} else {
			//singular element received
			return await api
				.delete(`/LinesDetails?guid=${object.Guid}`)
				.then((res) => {
					let response = JSON.parse(res.data);
					DPNotificationsEvents.dispatch(
						response.Message,
						response.ResponseType
					);
				});
		}
	};

	const useGetLines = () =>
		useQuery("AllLines", FetchLines, { refetchOnWindowFocus: false });
	const useGetLine = (guid) =>
		useQuery("LineGet", () => FetchLine(guid), { refetchOnWindowFocus: false });

	return {
		useGetLines,
		useGetLine,
		LinesTemplate,
		AddLine,
		UpdateLine,
		DeleteLine,
	};
};

const DPApiProvider = (props) => {
	const { children } = { ...props };
	const Profile = ProfileOps();
	const Notifications = NotificationsOps();
	const ServiceCaseType = CaseTypeOps();
	const ServiceType = TypeOps();
	const ServiceCaseDescriptionType = CaseDescriptionTypeOps();
	const CompanyInfo = CompaniesOps();
	const UsersInfo = UsersOps();
	const CaseDetails = CasesOps();
	const SourcesInfo = SourceOps();
	const StatusInfo = StatusOps();
	const BoxInfo = BoxDetailsOps();
	const LinesInfo = LineDetailsOps();

	const apiContextValue = {
		Profile,
		Notifications,
		ServiceCaseType,
		ServiceType,
		ServiceCaseDescriptionType,
		CompanyInfo,
		UsersInfo,
		CaseDetails,
		SourcesInfo,
		StatusInfo,
		BoxInfo,
		LinesInfo,
	};

	return (
		<DPAuthProvider>
			<QueryClientProvider client={queryCLient}>
				<DPApiContext.Provider value={apiContextValue} {...props}>
					{children}
				</DPApiContext.Provider>
			</QueryClientProvider>
		</DPAuthProvider>
	);
};

const useDPApi = () => useContext(DPApiContext);

export { DPApiProvider, useDPApi, useDPAuth };
